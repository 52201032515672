import { title, grayColor, whiteColor } from "../../trade-app.js";

const comingSoonStyles = () => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%)",
    textAlign: "center",
    color: grayColor[2],
    padding: "0 15px",
    width: "100%",
    maxWidth: "780px",
    //backgroundColor: blackColor,
  },
  title: {
    ...title,
    fontSize: "2.7em",
    color: grayColor[2],
    letterSpacing: "14px",
    fontWeight: "700"
  },
  subTitle: {
    fontSize: "1.25rem",
    marginTop: "0",
    marginBottom: "8px",
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px"
  }
});

export default comingSoonStyles;
