import { createHash } from "./crypto"

const generalRules = [
    {
        key: createHash("sadmin"),
        permissions: ["productos", "pdv", "clientes", "services", "users:read", "users:update", "dash-s", "photo:read", "photo:update", "photo:create"]
    },
    {
        key: createHash("admin"),
        permissions: ["productos", "pdv", "clientes", "services", "dash-a",  "photo:read", "photo:update"]
    },
    {
        key: createHash("client"),
        permissions: ["dash-c", "photo:read"]
    },
    {
        key: createHash("merchandiser"),
        permissions: ["photo:create"]
    }
]       

const getRules = key => {
    let rules = generalRules.find(x => x.key === key);
    rules = rules ? rules.permissions : []
    return rules
}

export default getRules;