const periodReport = [
    {
      ID: 'FV',
      name: 'Foto Visita',
    },
    {
      ID: 'FS',
      name: 'Foto Semanal',
    },
    {
      ID: 'FQ',
      name: 'Foto Quincenal',
    },
    {
      ID: 'FM',
      name: 'Foto Mensual',
    },
    {
      ID: 'IN',
      name: 'Inactivo',
    },
  ];
  export default periodReport;
  