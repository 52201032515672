export const isType = (type, report, image) => {
  const types = {
    favorites: image.favorite,
    pendings: !report.revised,
    revised: report.revised,
    all: true
  };

  return types[type] || false;
};
