import { useState } from 'react';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteTile from './DeleteTile';

import { makeStyles } from '@material-ui/core/styles';
import { useFirebase } from '../../../Firebase';
import { useFilters } from '../../../api/filters/FiltersProvider';
import { useCarousel } from './carousel/CarouselProvider';

const useStyles = makeStyles({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  titleBar: {
    position: 'absolute',
    bottom: 0,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  title: {
    color: 'rgba(255, 255, 255, 0.54)',
    padding: 5
  },
  photo: {
    //transform: 'rotate(-90deg)',
    transition: '100ms ease',
    transitionProperty: 'transform box-shadow',
    '&:hover': {
      opacity: '0.5'
    },
    objectFit: 'cover',
    maxWidth: '100%'
  },
  container: {
    position: 'relative',
    maxHeight: '100%'
  }
});

export default function Tile({
  tile,
  report,
  catIndex,
  disableAction = false
}) {
  const classes = useStyles();
  const [favorite, setFavorite] = useState(tile.favorite ?? false);
  const { firebase } = useFirebase();
  const [openModal, setOpenModal] = useState(false);
  const { setReportsXClient } = useFilters();

  const { setCarouselInfo } = useCarousel();

  const handleFavorite = async e => {
    e.stopPropagation();
    const categories = report.realCategories.map(category => {
      if (!category.images) return category;

      const images = category.images.map(image =>
        image.name === tile.name
          ? {
              ...image,
              favorite: !favorite
            }
          : image
      );

      return {
        ...category,
        images
      };
    });

    await firebase.db.collection('reports').doc(report.id).update({
      categories
    });

    setReportsXClient(reports =>
      reports.map(r => (r.id === report.id ? { ...r, categories } : r))
    );

    setFavorite(!favorite);
  };

  const deleteTile = async reason => {
    await firebase.storage
      .refFromURL(currentTile.uri)
      .delete()
      .catch(() => console.log('La imagen no existe'));

    const categories = report.realCategories.map(category => {
      if (!category.images) return category;
      const images = category.images.map(image =>
        image.name === tile.name ? { ...image, isDeleted: true, reason } : image
      );

      return {
        ...category,
        images
      };
    }, []);

    await firebase.db.collection('reports').doc(report.id).update({
      categories
    });

    setReportsXClient(reports =>
      reports.map(r => (r.id === report.id ? { ...r, categories } : r))
    );

    setOpenModal(false);
  };

  return (
    <div
      onClick={() =>
        setCarouselInfo({
          report,
          tileInfo: { tile, catIndex }
        })
      }
      className={classes.container}
    >
      <DeleteTile
        onClose={() => setOpenModal(false)}
        open={openModal}
        onDelete={deleteTile}
      />
      <img className={classes.photo} src={tile.uri} alt={tile.name} />
      <GridListTileBar
        titlePosition="bottom"
        classes={{ root: classes.titleBar, title: classes.title }}
        actionIcon={
          !disableAction && (
            <>
              <IconButton
                onClick={handleFavorite}
                className={classes.title}
                value={tile.uri}
              >
                <Icon>{favorite ? 'star' : 'star_border'}</Icon>
              </IconButton>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setOpenModal(true);
                }}
                className={classes.title}
                value={tile.uri}
              >
                <Icon>delete</Icon>
              </IconButton>
            </>
          )
        }
      />
    </div>
  );
}
