//routes paths
import * as ROUTES from '../constants/routes';

//ADMIN
import DashboardAdmin from '../Pages/Admin/Dashboard/Dashboard.js';
import ClientsCrud from '../Pages/Admin/Crud/ClientsPage';
import RetailCrud from '../Pages/Admin/Crud/RetailPage';
import CoveragesCrud from '../Pages/Admin/Crud/CoveragesPage';
import ProductsCrud from '../Pages/Admin/Crud/ProductsPage';
import Accordion from '../Pages/Admin/Revision/Accordion';
import Revision from '../Pages/Admin/Revision/RevisionPage.js';
import Productivity from '../Pages/Admin/Productivity/ProductivityPage.js';
import AddReportPage from '../Pages/Admin/Revision/AddReportPage';

//SUPER_ADMIN
import DashboardSuperAdmin from '../Pages/SuperAdmin/Dashboard/Dashboard.js';
import UsersCrud from '../Pages/SuperAdmin/UsersPage';
import AllClientsCrud from '../Pages/SuperAdmin/ClientsPage';


//CLIENT
import DashboardClient from '../Pages/Client/Dashboard/Dashboard.js';

//COMMON
import Login from '../Pages/Common/Login/LoginPage';
import Landing from '../Pages/Common/Landing/LandingPage';
import Contact from '../Pages/Common/Contact/ContactPage';
import Error from '../Pages/Common/ErrorPage';
import UnauthorizedError from '../Pages/Common/UnauthorizedErrorPage';

import {
  LockOpen,
  PhotoLibrary,
  Dashboard,
  Store,
  Person,
  ShoppingCart,
  Business,
  Group,
  TrendingUp, 
  AddPhotoAlternate,
  Pageview,
  List
} from '@material-ui/icons';

const routes = [
  ///////////////////////
  ////** Commons **//////
  ///////////////////////
  {
    path: `${ROUTES.LOGIN}`,
    component: Login,
    name: 'Login',
    icon: LockOpen,
    isPrivate: false,
    layout: `${ROUTES.L_AUTH}`
  },
  {
    path: `${ROUTES.LANDING}`,
    component: Landing,
    name: 'Home',
    icon: LockOpen,
    isPrivate: false,
    layout: `${ROUTES.L_AUTH}`
  },
  {
    path: `${ROUTES.CONTACT}`,
    component: Contact,
    name: 'Contacto',
    icon: LockOpen,
    isPrivate: false,
    layout: `${ROUTES.L_AUTH}`
  },
  {
    path: `${ROUTES.ERROR}`,
    component: Error,
    isPrivate: false,
    layout: `${ROUTES.L_AUTH}`
  },
  {
    path: `${ROUTES.UNAUTHORIZED}`,
    component: UnauthorizedError,
    isPrivate: false,
    layout: `${ROUTES.L_AUTH}`
  },

  /////////////////////
  ////** Admin **//////
  /////////////////////
  {
    path: `${ROUTES.ADASHBOARD}`,
    name: 'Resumen',
    component: DashboardAdmin,
    icon: Dashboard,
    isPrivate: true,
    layout: `${ROUTES.L_ADMIN}`
  },
  {
    collapse: true,
    name: 'Reportes',
    icon: PhotoLibrary,
    state: "reportCollapse",
    layout: `${ROUTES.L_ADMIN}`,
    views: 
    [
      {
        path: `${ROUTES.REVISION}`,
        component: Revision,
        name: 'Revisar Reportes',
        icon: Pageview,
        isPrivate: true,
        layout: `${ROUTES.L_ADMIN}`,

      },
      {
        path: `/crear-reporte`,
        component: AddReportPage,
        name: 'Crear Reporte',
        icon: AddPhotoAlternate,
        isPrivate: true,
        layout: `${ROUTES.L_ADMIN}`,
      },
    ]
  },
  {
    path: `${ROUTES.CLIENTS}`,
    component: ClientsCrud,
    name: 'Clientes',
    icon: Person,
    isPrivate: true,
    layout: `${ROUTES.L_ADMIN}`
  },
  {
    path: `${ROUTES.COVERAGES}`,
    component: CoveragesCrud,
    name: 'Anexos',
    icon: List,
    isPrivate: true,
    layout: `${ROUTES.L_ADMIN}`
  },
  {
    path: `${ROUTES.RETAIL}`,
    component: RetailCrud,
    name: 'Tiendas',
    icon: Store,
    isPrivate: true,
    layout: `${ROUTES.L_ADMIN}`
  },
  {
      path: `${ROUTES.PRODUCTS}`,
      component: ProductsCrud,
      name: "Productos",
      icon: ShoppingCart,
      isPrivate: true,
      layout: `${ROUTES.L_ADMIN}`
  },
  {
    path: `${ROUTES.PRODUCTIVITY}`,
    component: Productivity,
    name: "Mi Productividad",
    icon: TrendingUp,
    isPrivate: true,
    layout: `${ROUTES.L_ADMIN}`
},

  ////////////////////
  ////** Client **////
  ////////////////////
  {
    path: `${ROUTES.CDASHBOARD}`,
    name: 'Resumen',
    component: DashboardClient,
    icon: Dashboard,
    isPrivate: true,
    layout: `${ROUTES.L_CLIENT}`
  },
  // {
  //     path: `${ROUTES.REPORTS}`,
  //     component: FilterPictures2,
  //     name: "Fotografías 2",
  //     icon: PhotoCamera,
  //     isPrivate: true,
  //     layout: `${ROUTES.L_CLIENT}`
  // },
  {
    path: `${ROUTES.REPORTS}`,
    component: Accordion,
    name: 'Reportes fotográfico',
    icon: PhotoLibrary,
    isPrivate: true,
    layout: `${ROUTES.L_CLIENT}`
  },
  // {
  //     path: '/photos4',
  //     component: PinterestStyle,
  //     name: "Fotografías 4",
  //     icon: PhotoCamera,
  //     isPrivate: true,
  //     layout: `${ROUTES.L_CLIENT}`
  // },

  //SuperAdmin
  {
    path: `${ROUTES.SDASHBOARD}`,
    name: 'Resumen',
    component: DashboardSuperAdmin,
    icon: Dashboard,
    isPrivate: true,
    layout: `${ROUTES.L_SUPERADMIN}`
  },
  {
    path: `${ROUTES.USERS}`,
    component: UsersCrud,
    name: 'Usuarios',
    icon: Group,
    isPrivate: true,
    layout: `${ROUTES.L_SUPERADMIN}`
  },
  {
    path: `${ROUTES.ALLCLIENTS}`,
    component: AllClientsCrud,
    name: 'Clientes',
    icon: Business,
    isPrivate: true,
    layout: `${ROUTES.L_SUPERADMIN}`
  }
];

export default routes;
