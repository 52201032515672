import  { useState, useEffect } from 'react';
import TableCrud from '../../../components/Table/TableCrud';
import { useFirebase } from '../../../Firebase';

const GetCoverages = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { firebase } = useFirebase();

  const columns = [  
    {
      field: "displayName",
      title: "Nombre",
      description: "Nombre del usuario",
    },
        {
          field: "email",
          title: "Email",
          description: "Email del usuario",
        },
        {
          field: "role",
          title: "Tipo de usuario",
          description: "Rol del usuario",
        },
        {
          field: "ID",
          title: "Identificador",
        },
  ];


  const getUsers = async () => {
    try {
      setLoading(true);
      let result = await firebase.db.collection("users").orderBy('displayName').get();
      let users = result.docs.map((_user) => {
        return { ..._user.data(), id: _user.id };
      });
      setUsers(users);
    } catch (error) {
      console.error(`GetUsers - Ocurrió el error: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers()
  }, []);

  return (
    <>
      {users && (
        <div>
          <TableCrud
            title="Usuarios"
            data={users}
            columns={columns}
            isLoading={loading}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                    firebase.db
                      .collection('users')
                      .doc(`${oldData.id}`)
                      .delete()
                      .then(function () {
                        console.log('Document successfully deleted!');
                        getUsers()
                      })
                      .catch(function (error) {
                        console.error('Error removing document: ', error);
                      });
                    resolve();
                })
            }}
          />
        </div>
      )}
    </>
  );
};

export default GetCoverages;
