import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFirebase } from '../../../Firebase';
import * as yup from 'yup';
import 'yup-phone';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../../components/Grid/GridItem.js';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput.js';

import styles from '../../../assets/jss/trade-app/views/registerPageStyle';

const useStyles = makeStyles(styles);

export default function ContactPage({closeModal}) {
  const [disabled, setDisabled] = useState(false);
  const { firebase } = useFirebase();
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('Formato de correo electrónico inválido')
          .required('Campo obligatorio'),
        name: yup.string().required('Campo obligatorio'),
        phone: yup.string().required('Campo obligatorio'),
        message: yup.string()
      })
    ),
    mode: 'all'
  });

  const onSubmit = async values => {
    setDisabled(true);
    await firebase.db.collection('contact').add(values);
    setOpen(true);
    reset();
    setDisabled(false);
  };

  const classes = useStyles();
  return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
            <GridItem xs={12} sm={12} md={10}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.form}
                  >
                    <CustomInput
                  
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        inputRef: register,
                        name: 'name',
                        placeholder: 'Nombre'
                      }}
                    />
                    <CustomInput
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        name: 'email',
                        inputRef: register,
                        placeholder: 'Correo electrónico'
                      }}
                    />
                    <CustomInput
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        name: 'phone',
                        inputRef: register,
                        placeholder: 'Teléfono'
                      }}
                    />
                    <CustomInput
                      labelText="Mensaje (opcional)"
                      id="message"
                      error={!!errors.message}
                      helperText={errors.message?.message}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        name: 'message',
                        inputRef: register,
                        multiline: true,
                        rows: 3
                      }}
                    />
                    <div className={classes.center}>
                      <br />
                      <Button disabled={disabled} onClick={closeModal} color="transparent">
                        Cerrar
                      </Button>
                      <Button disabled={disabled} type="submit" color="primary">
                        Enviar
                      </Button>
                    </div>
                  </form>
                </GridItem>
                
                </GridItem>

              </GridContainer>
    
  );
}



