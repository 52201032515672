import { useState, useEffect } from 'react';
import admins from '../../../data/admins';
import comercials from '../../../data/comercials';
import TableCrud from '../../../components/Table/TableCrud';
import CustomLinearProgress from '../../../components/CustomLinearProgress/CustomLinearProgress';
import { useFirebase } from '../../../Firebase';
import { withRouter, useHistory } from "react-router-dom";

const GetClients = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const { firebase } = useFirebase();
  const history = useHistory();

  const columns = [
    { title: 'Identificador', field: 'ID'},
    { title: 'Empresa', field: 'companyName' },
    { title: 'Nombre', field: 'name' },
    { title: 'Contacto', field: 'contact' },
    { title: 'Back Office', field: 'admin' },
    { title: 'Comercial', field: 'comercial' },
    // { title: 'Frecuencia fotos', field: 'periodReport' }
  ];

  const getClientsFirebase = async() => {
    let cli = [];
        const result = await firebase.db
                .collection('clients')
                .orderBy('ID')
                .get(); 
        cli = result.docs.map(client => ({
                ...client.data(),
                id: client.id
        }));
        //console.log(clientsData)
        setClientsData(cli)
  }

  useEffect(() => {
    getClientsFirebase()
  },[]),

  useEffect(() => {
    let clientsList = []
    const getClients = async () => {     
    clientsData.map(async client => {
          let nClient = {
            ID: client.ID,
            companyName: client.companyName,
            name: client.name,
            contact: client.contactName,
            admin: `${admins.filter(adm => adm.ID == client.adminId)[0]?.name}`,
            comercial: `${comercials.filter(com => com.ID == client.comercialId)[0]?.name}`,
            id: client.id,
            //periodReport: `${frequencyString.filter(pr => pr.ID == client.periodReportId)[0]?.name}`
          };
          //console.log(nClient);
          clientsList.push(nClient);
        });
        setLoading(false);
    }
    getClients();
    setData(clientsList);
  }, [clientsData])

  return (
    <>
      {loading ? (
        <CustomLinearProgress />
      ) : (
        <>
          {data && (
            <div>
              <TableCrud
                title="Clientes"
                data={data}
                columns={columns}
                loading={loading}
                 editable={{
                  onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                      firebase.db
                        .collection('clients')
                        .doc(`${oldData.id}`)
                        .delete()
                        .then(function () {
                          console.log('Document successfully deleted!');
                          getClientsFirebase()
                        })
                        .catch(function (error) {
                          console.error('Error removing document: ', error);
                        });
                      resolve();
                  })
              }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(GetClients);
