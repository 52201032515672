import { useSuperAdmin } from '../../../api/useSuperAdmin';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
//styles
import styles from '../../../assets/jss/trade-app/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function DashboardAdmin() {
  const { name } = useSuperAdmin();
  const classes = useStyles();

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h3>{`¡Hola ${name}!`}</h3>
        </GridItem>
        <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.title}>
            Próximamente
          </h3>
          <h5 className={classes.subTitle}>
          Podrás visualizar la actividad de todas las cuentas
          </h5>
        </GridItem>
      </GridContainer>
    </div>
      </GridContainer>
    </>
  );
}
