import { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Paper } from "@material-ui/core";

import { FirebaseContext } from "../../../Firebase";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import { createHash } from "../../../Services/crypto.js";
import Button from "../../../components/CustomButtons/Button.js";
import SuccessAlert from "../../../myComponents/SuccessAlert";
import Error from "../../../myComponents/Error";

const CreateUser = () => {
  const { firebase } = useContext(FirebaseContext);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const history = useHistory();
  const roles = [
    { name: "Back Office / Supervisor", value: "admin" },
    { name: "Cliente", value: "client" },
    { name: "Administrador", value: "sadmin" },
    { name: "Merchandiser", value: "merchandiser" },
  ];

  const onConfirm = () => {
    history.push("/sadmin/usuarios");
  };

  async function handleSubmit(e) {
    e.preventDefault();
    const {
      displayName,
      email,
      password,
      passwordConfirm,
      role,
    } = e.target.elements;
    setLoading(true);

    await firebase.auth
      .createUserWithEmailAndPassword(email.value, password.value)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await firebase.db
          .collection("users")
          .doc(user.uid)
          .set({
            displayName: displayName.value,
            email: email.value,
            password: password.value,
            role: role.value,
            key: createHash(role.value),
          });
        //console.log('usuario creado', user)
        setCreate(true);
      })
      .catch((error) => {
        console.log(error.code);
        // An error happened.
        if (error.code == "auth/invalid-email") {
          setError("Formato de correo electrónico inválido");
        }
        if (error.code == "auth/weak-password") {
          setError("La contraseña debe tener al menos 6 caracteres");
        }
        if (error.code == "auth/email-already-in-use") {
          setError(
            "El correo electrónico ya se encuentra en uso por otro usuario"
          );
        }
        if (password.value !== passwordConfirm.value) {
          return setError("Las contraseñas no coinciden.");
        }
      });
  }

  return (
    <Paper elevation={3} style={{ padding: 30 }}>
    <GridContainer justify="center">
      <GridItem xs={10} sm={10} md={5}>
        <h3 className="text-center mb-4">Crear Usuario</h3>
        {create ? (
          <SuccessAlert msg="Usuario creado con éxito" onConfirm={onConfirm} />
        ) : null}
        {error && <Error msg={error} />}
        <form onSubmit={handleSubmit}>
          <TextField
            //variant="outlined"
            margin="normal"
            required
            fullWidth
            id="displayName"
            label="Nombre y apellido"
            name="displayName"
            autoComplete="displayName"
            autoFocus
          />
          <TextField
            //variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            //variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            //variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordConfirm"
            label="Repetir contraseña"
            type="password"
            id="passwordConfirm"
            autoComplete="current-password"
          />
          <TextField
            select
            //variant="outlined"
            margin="normal"
            required
            fullWidth
            name="role"
            label="Rol / Nivel de acceso"
            id="role"
            type="text"
            SelectProps={{
              native: true,
            }}
            helperText="Este rol determinará el nivel de acceso que tendrá el nuevo usuario"
          >
            {roles.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </TextField>
          <div style={{ paddingTop: 40 }}>
            <Button fullWidth type="submit" color="primary">
              Crear usuario
            </Button>
          </div>
        </form>
      </GridItem>
    </GridContainer>
    </Paper>
  );
};

export default withRouter(CreateUser);
