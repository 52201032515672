import { useState, useEffect } from 'react';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import { useAdminClients } from '../../../../api/useAdmin';
import TableCrud from '../../../../components/Table/TableCrud';
import CustomLinearProgress from '../../../../components/CustomLinearProgress/CustomLinearProgress';

const GetClients = () => {
  const { clientsData, admins, comercials } = useFilters();
  const { loading } = useAdminClients();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const columns = [
    { title: 'Identificador', field: 'id' },
    { title: 'Empresa', field: 'companyName' },
    { title: 'Nombre', field: 'name' },
    { title: 'Contacto', field: 'contact' },
    { title: 'Back Office', field: 'admin' },
    { title: 'Comercial', field: 'comercial' },
    // { title: 'Frecuencia fotos', field: 'periodReport' }
  ];


  useEffect(() => {
    let clients = [];
    const getClients = async () => {
      try {
        setIsLoading(true);
        clientsData.map(async client => {
          let nClient = {
            id: client.ID,
            companyName: client.companyName,
            name: client.name,
            contact: client.contactName,
            admin: `${admins.filter(adm => adm.ID == client.adminId)[0]?.name}`,
            comercial: `${comercials.filter(com => com.ID == client.comercialId)[0]?.name}`,
            //periodReport: `${frequencyString.filter(pr => pr.ID == client.periodReportId)[0]?.name}`
          };
          //console.log(nClient);
          clients.push(nClient);
        });
        setIsLoading(false);
      } catch (error) {
        console.error(`getClients. Ocurrió el error: ${error}`);
      }
      setIsLoading(false);
    };
    getClients();
    setData(clients);
  }, [clientsData]);

  return (
    <>
      {loading ? (
        <CustomLinearProgress />
      ) : (
        <>
          {data && (
            <div>
              <TableCrud
                title="Clientes"
                data={data}
                columns={columns}
                isLoading={isLoading}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GetClients;
