const firebaseConfig = {
    apiKey: "AIzaSyA80r0YY_iSP90J1jZbb0PQmFBw7Mosmd8",
    authDomain: "chek-app.firebaseapp.com",
    projectId: "chek-app",
    storageBucket: "chek-app.appspot.com",
    messagingSenderId: "311708150226",
    appId: "1:311708150226:web:851f9abec23687577c000b",
    measurementId: "G-0QVFKT2VGG"
};
  
export default firebaseConfig;

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENT_ID,
//   };
