import getRules from "../../Services/rules.js";
import { getItem } from "../../Services/localStorage.js";
import UnauthorizedErrorPage from "../../Pages/Common/UnauthorizedErrorPage.js"

const check = action => {
    const key = getItem("key")
    const permissions = getRules(key);
    if (!permissions) return false;
    if (permissions && permissions.includes(action)) return true;
}

const Can = props =>
    check(props.action)
        ? props.yes
        : props.no;

Can.defaultProps = {
    yes: () => null,
    no: <UnauthorizedErrorPage/>
};


export default Can;