import { useState, useEffect } from 'react';
import { useFilters } from '../../../../api/filters/FiltersProvider';
// components
import Card from '../../../../components/Card/Card.js';
import GridItem from '../../../../components/Grid/GridItem';
import CardIcon from '../../../../components/Card/CardIcon';
import CardHeader from '../../../../components/Card/CardHeader';
import CardFooter from '../../../../components/Card/CardFooter';
import GridContainer from '../../../../components/Grid/GridContainer';
// @material-ui/core components
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
// @material-ui/icons
import Visibility from '@material-ui/icons/Visibility';
//chek-icons
import Shop from '../../../../assets/img/icon-shop.png';
import Visits from '../../../../assets/img/icon-visits.png';
import Camera from '../../../../assets/img/icon-camera.png';
import Alert from '../../../../assets/img/icon-alert.png';


export const DashboardCards = ({
  classes,
  chainsCardOpen,
  photosCardOpen,
  visitsCardOpen,
  handleChainsCard,
  handlePhotosCard,
  handleVisitsCard,
  complianceCardOpen,
  handleComplianceCard
}) => {
  const {
    client,
    chain,
    isLoading,
    reportsXChain,
    branchesXChain,
    reportsXClient,
    branchesXClient,
    OFC,
    OFCpendings,
  } = useFilters();

  const [dayOfWeek, setDayOfWeek] = useState('');
  const [actualDate, setActualDate] = useState('');
  const [actualOFC, setActualOFC] = useState([]);
  const [OFCPorc, setOFCPorc] = useState();

  //FOTO SEMANAL//
  //OBTENER DÍA DE LA SEMANA ACTUAL
  const getDayOfWeek = () => {
    const date = new Date();
    const week = new Array();
    week[0] = 'Domingo';
    week[1] = 'Lunes';
    week[2] = 'Martes';
    week[3] = 'Miércoles';
    week[4] = 'Jueves';
    week[5] = 'Viernes';
    week[6] = 'Sábado';
    setDayOfWeek(week[date.getDay()]);
  };

  const getActualDate = () => {
    var date = new Date();
    setActualDate(date.toLocaleTimeString());
  };

  const getOFCPorc = () => {
    setOFCPorc(100 - Math.trunc((OFCpendings.length * 100) / OFC.length))
  }

  useEffect(() => {
    getActualDate();
    getDayOfWeek(); 
  }, []);

  
  useEffect(() => {
    getOFCPorc()
  }, [OFCpendings]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary" >
            <div>
                <img src={Shop} widht="60" height= "60px"/>
                </div>
            </CardIcon>
            <p className={classes.cardCategory}>Tiendas contratadas</p>
            <div className={classes.progressContainer}>
              {isLoading ? (
                <LinearProgress className={classes.progress} />
              ) : (
                <h3 className={classes.cardTitle}>
                  {chain.ID? branchesXChain.length : branchesXClient.length}
                  <small>{chain.ID ? ` (${branchesXClient.length})` : null}</small>
                </h3>
              )}
            </div>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Visibility />
              <Link
                underline="none"
                color="textSecondary"
                align="center"
                onClick={handleChainsCard}
                href="#"
              >
                {chainsCardOpen ? (
                  <span>Ocultar detalle</span>
                ) : (
                  <span>Ver detalle</span>
                )}
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              <div>
                <img src={Camera} widht="60" height= "60"/>
              </div>
            </CardIcon>
            <Tooltip
              id="tooltip-top"
              title="Objetivo fotos contratadas"
              placement="bottom-end"
              classes={{ tooltip: classes.tooltip }}
            >
              <p className={classes.cardCategory}>OFC</p>
            </Tooltip>
            <h3 className={classes.cardTitle}>
              {isLoading ? (
                <LinearProgress className={classes.progress} />
              ) :
                OFC.length
              }
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Visibility />
              <Link
                underline="none"
                color="textSecondary"
                align="center"
                onClick={handlePhotosCard}
                href="#"
              >
                {photosCardOpen ? (
                  <span>Ocultar detalle</span>
                ) : (
                  <span>Ver detalle</span>
                )}
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              <div>
                <img src={Visits} widht="60" height= "60" />
              </div>
            </CardIcon>
            <p className={classes.cardCategory}>Tiendas con fotos</p>
            {isLoading ? (
              <LinearProgress className={classes.progress} />
            ) : (
              <h3 className={classes.cardTitle}>
                {chain.ID ? reportsXChain.length : reportsXClient.length}
                <small>{` (${
                  chain.ID ? branchesXChain.length : branchesXClient.length
                })`}</small>
              </h3>
            )}
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Visibility />
              <Link
                href="#"
                underline="none"
                color="textSecondary"
                align="center"
                onClick={handleVisitsCard}
              >
                {visitsCardOpen ? (
                  <span>Ocultar detalle</span>
                ) : (
                  <span>Ver detalle</span>
                )}
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={6} lg={3}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              <div>
                <img src={Alert} widht="60" height= "60" />
              </div>
            </CardIcon>
            <Tooltip
              id="tooltip-top"
              title="Nivel de cumplimiento de fotos contratadas"
              placement="bottom-end"
              classes={{ tooltip: classes.tooltip }}
            >
              <p className={classes.cardCategory}>Cumplimiento OFC</p>
            </Tooltip>
            {isLoading ? (
              <LinearProgress className={classes.progress} />
            ) : (
              <h3 className={classes.cardTitleRed}>
                {
                  client ?
                (<>{`${OFCPorc}%`}</>)
                :(
                <>
                <span>0%</span>
                </>
              )
            }
              </h3>
            )}
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Visibility fontSize="small" />
              <Link
                underline="none"
                color="textSecondary"
                align="center"
                onClick={handleComplianceCard}
                href="#"
              >
                {complianceCardOpen ? (
                  <span>Ocultar detalle</span>
                ) : (
                  <span>Ver detalle</span>
                )}
              </Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
