import { useContext, useState } from 'react';
import admins from '../../../data/admins';
import comercials from '../../../data/comercials';
import { withRouter, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FirebaseContext } from '../../../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/Add';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Divider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import GridItem from '../../../components/Grid/GridItem.js';
import GridContainer from '../../../components/Grid/GridContainer.js';
import Switch from '@material-ui/core/Switch';
import CustomInput from '../../../components/CustomInput/CustomInput.js';
import Button from '../../../components/CustomButtons/Button.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import SuccessAlert from '../../../myComponents/SuccessAlert';
import styles from '../../../assets/jss/trade-app/views/regularFormsStyle';



const useStyles = makeStyles(styles);

const CreateClient = () => {
  const classes = useStyles();
  // Context con las operaciones de firebase
  const { firebase } = useContext(FirebaseContext);
  const [moreCategories, setMoreCategories] = useState(false);
  const [create, setCreate] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState();

  const onConfirm = () => {
    history.push('/sadmin/clientes', 0);
    setCreate(false)
  };

  const formik = useFormik({
    initialValues: {
      companyName: '',
      name: '',
      ID: '',
      email: '',
      phone: '',
      CUIT: '',
      address: '',
      adminId: '',
      comercialId: '',
      contactName: '',
      control: false,
      description: '',
      categories: ['']
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string()
        .trim()
        .min(5, 'Debe tener al menos 5 caracteres')
        .required('Campo obligatorio'),
      address: Yup.string()
        .min(5, 'Debe tener al menos 5 caracteres')
        .required('Campo obligatorio'),
      name: Yup.string()
        .min(3, 'Debe tener al menos 3 caracteres')
        .required('Campo obligatorio'),
      ID: Yup.string()
      .matches(/^[0-9]*$/ , 'Código interno númerico')
        .max(3, 'Revise este campo')
        .required('Campo obligatorio'),
      adminId: Yup.string().required('Campo obligatorio'),
      comercialId: Yup.string().required('Campo obligatorio'),
      email: Yup.string()
        .email('Formato de email inválido')
        .required('Campo obligatorio'),
      phone: Yup.string()
      .matches(/^[0-9]*$/ , 'Sólo números'),
      CUIT: Yup.string()
        .min(10, 'Debe tener al menos 10 caracteres')
        .matches(/^[0-9]*$/ , 'Sólo números')
        .max(20, 'Revise este campo')
        .required('Campo obligatorio'),
      contactName: Yup.string()
        .min(3, 'Debe tener al menos 3 caracteres')
        .matches(/[abcdefghijklmnopqrstuvwxyz]+/ , 'Sólo puede contener letras')
        .required('Campo obligatorio'),
      description: Yup.string().min(5, 'La descripción debe ser más larga'),
      categories: Yup.array()
        .of(Yup.string().required('Debe completar la categoría')
        .matches(/[abcdefghijklmnopqrstuvwxyz]+/ , 'Sólo puede contener letras'))
        .min(1, 'Tenés que cargar al menos una categoría')
    }),
    onSubmit: async ({ categories, ...client }) => {
      setIsLoading(true);
      client.existencia = true;
      try {
        const docRef = await firebase.db.collection('clients').add(client);
        console.log('Document written with ID: ', docRef.id);
      await Promise.all(
        categories.map(async (category, index) => {
           firebase.db.collection('categories').add({
            ID: `CAT${index + 1}`,
            name: category,
            clientId: client.ID
          });
        })
      );
      setCreate(true);
      formik.resetForm({});    
      } catch (err) {
        console.error('Error', err);
      }
      setIsLoading(false);
    }
  });

  const handleMore = () => {
    formik.setFieldValue('categories', [...formik.values.categories, '']);
    //console.log(moreCategories);
  };


  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper elevation={3} style={{ padding: 30 }}>
        {create && (
          <SuccessAlert msg="Cliente creado con éxito" onConfirm={onConfirm} />
        )}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  <legend>Información general</legend>
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={10} md={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Razón social"
                      id="companyName"
                      required
                      name="companyName"
                      helperText={
                        formik.errors.companyName &&
                        formik.touched.companyName ? (
                          <span>{formik.errors.companyName}</span>
                        ) : null
                      }
                      error={
                        formik.errors.companyName && formik.touched.companyName
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.companyName,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nombre comercial"
                      id="name"
                      required
                      name="name"
                      helperText={
                        formik.errors.name && formik.touched.name ? (
                          <span>{formik.errors.name}</span>
                        ) : null
                      }
                      error={formik.errors.name && formik.touched.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.name,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Identificador"
                      id="ID"
                      required
                      name="ID"
                      helperText={
                        formik.errors.ID && formik.touched.ID ? (
                          <span>{formik.errors.ID}</span>
                        ) : null
                      }
                      error={formik.errors.ID && formik.touched.ID}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.ID,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      required
                      name="email"
                      helperText={
                        formik.errors.email && formik.touched.email ? (
                          <span>{formik.errors.email}</span>
                        ) : null
                      }
                      error={formik.errors.email && formik.touched.email}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.email,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Teléfono"
                      id="phone"
                      required
                      name="phone"
                      helperText={
                        formik.errors.phone && formik.touched.phone ? (
                          <span>{formik.errors.phone}</span>
                        ) : null
                      }
                      error={formik.errors.phone && formik.touched.phone}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.phone,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="CUIT"
                      id="CUIT"
                      required
                      name="CUIT"
                      helperText={
                        formik.errors.CUIT && formik.touched.CUIT ? (
                          <span>{formik.errors.CUIT}</span>
                        ) : null
                      }
                      error={formik.errors.CUIT && formik.touched.CUIT}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.CUIT,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Dirección"
                      id="address"
                      required
                      name="address"
                      helperText={
                        formik.errors.address && formik.touched.address ? (
                          <span>{formik.errors.address}</span>
                        ) : null
                      }
                      error={formik.errors.address && formik.touched.address}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.address,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre de contacto"
                      id="contactName"
                      required
                      name="contactName"
                      helperText={
                        formik.errors.contactName &&
                        formik.touched.contactName ? (
                          <span>{formik.errors.contactName}</span>
                        ) : null
                      }
                      error={
                        formik.errors.contactName && formik.touched.contactName
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formik.values.contactName,
                        onBlur: formik.handleBlur,
                        onChange: event => formik.handleChange(event)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      size="small"
                      id="adminId"
                      name="adminId"
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={option => option.name}
                      options={admins}
                      openOnFocus
                      onChange={(e, value) => {
                        //console.log(e.value);
                        formik.setFieldValue(
                          'adminId',
                          value != null
                            ? value.ID
                            : formik.initialValues.adminId
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Back Office"
                          margin="normal"
                          helperText={
                            formik.errors.adminId && formik.touched.adminId ? (
                              <span>{formik.errors.adminId}</span>
                            ) : null
                          }
                          error={
                            formik.errors.adminId && formik.touched.adminId
                          }
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Autocomplete
                      size="small"
                      id="comercialId"
                      name="comercialId"
                      options={comercials}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      getOptionLabel={option => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(
                          'comercialId',
                          value != null
                            ? value.ID
                            : formik.initialValues.comercialId
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Comercial"
                          margin="normal"
                          helperText={
                            formik.errors.comercialId &&
                            formik.touched.comercialId ? (
                              <span>{formik.errors.comercialId}</span>
                            ) : null
                          }
                          error={
                            formik.errors.comercialId &&
                            formik.touched.comercialId
                          }
                        />
                      )}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Descripción"
                          id="description"
                          required
                          name="description"
                          helperText={
                            formik.errors.description &&
                            formik.touched.description ? (
                              <span>{formik.errors.description}</span>
                            ) : null
                          }
                          error={
                            formik.errors.description &&
                            formik.touched.description
                          }
                          formControlProps={{
                            fullWidth: true
                          }}
                          variant="outlined"
                          inputProps={{
                            value: formik.values.description,
                            onBlur: formik.handleBlur,
                            onChange: event => formik.handleChange(event),
                            placeholder:
                              'Agregue información adicional que considere relevante sobre el cliente (no obligatorio)',
                            fontSize: '10px',
                            multiline: true,
                            rows: 3
                          }}
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          name="control"
                          color="primary"
                          checked={formik.values.control}
                          onChange={formik.handleChange}
                          value={formik.values.control}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Requiere control de reportes fotográficos"
                    />
                    {formik.values.control && (
                      <h6>
                        El cliente no podrá ver las fotografías tomadas en los
                        puntos de venta hasta que el analista de cuentas a cargo
                        las apruebe.
                      </h6>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <Divider light />

            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <FormLabel className={classes.labelHorizontal}>
                  <legend style={{ alignSelf: 'flex-start' }}>
                    Categorías de productos{' '}
                  </legend>
                </FormLabel>
              </GridItem>

              <GridItem xs={12} sm={12} md={10}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer>
                          {formik.values.categories.map((category, index) => (
                            <GridItem xs={12} sm={12} md={3}>
                              <CustomInput
                                labelText={`Categoría ${index + 1}`}
                                id={`categories[${index}]`}
                                required
                                name={`categories[${index}]`}
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={
                                  formik.errors.categories &&
                                  formik.errors.categories[index]
                                }
                                helperText={
                                  formik.errors.categories &&
                                  formik.errors.categories[index]
                                }
                                inputProps={{
                                  value: category,
                                  // value: formik.values.categories.,
                                  //onBlur: formik.handleBlur,
                                  onChange: formik.handleChange
                                }}
                              />
                            </GridItem>
                          ))}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          size="sm"
                          round
                          className={classes.buttonAdd}
                          onClick={handleMore}
                          icon={moreCategories ? <Add /> : null}
                        >
                          + Categorías
                        </Button>
                        {formik.values.categories.length > 1 && (
                          <Button
                            size="sm"
                            round
                            className={classes.buttonAdd}
                            onClick={() =>
                              formik.setFieldValue(
                                'categories',
                                formik.values.categories.slice(0, -1)
                              )
                            }
                            icon={moreCategories ? <Add /> : null}
                          >
                            - Categorías
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <br />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <Button
              disabled={isLoading}
              type="submit"
              color="primary"
              fullWidth
            >
              Crear Cliente
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
    </form>
  );
};

export default withRouter(CreateClient);
