import { useState, useEffect } from 'react';
import GridItem from '../../../components/Grid/GridItem.js';
import ImageUpload from '../../../components/CustomUpload/ImageUpload.js';
import { v4 as uuidv4 } from 'uuid';

export default function CategoryReportForm({ category, setCategories }) {
  const [images, setImages] = useState(category.images ?? []);

  const onRemove = ({ name }) => {
    setImages(images.filter(image => image.name !== name));
  };

  useEffect(() => {
    setCategories(categories =>
      categories.map(cat =>
        cat.id === category.id
          ? {
              ...category,
              images
            }
          : cat
      )
    );
  }, [images]);

  const onChange = (image, type) => {
    const updatedImages = images.map(i =>
      i.name === image.name ? { ...image, type } : i
    );
    setImages(updatedImages);
  };

  const addImage = (image, type) => {
    setImages([
      ...images,
      {
        ...image,
        type,
        name: `${uuidv4()}-${type}.jpg`
      }
    ]);
  };

  console.log(images);

  return (
    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
      <GridItem xs={12} sm={12} md={12}>
        <p style={{ fontWeight: '400' }}>Exhibición primaria (máx. 5 fotos)</p>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          {images
            .filter(image => image.type === 'primary')
            .map(image => (
              <ImageUpload
                name={image.name}
                key={image.name}
                baseFile={image.file}
                basePreviewUrl={image.imagePreviewUrl}
                onChange={image => onChange(image, 'primary')}
                onRemove={onRemove}
              />
            ))}
          {images.filter(image => image.type === 'primary').length < 5 && (
            <ImageUpload
              onlyUpload
              onChange={image => addImage(image, 'primary')}
            />
          )}
        </div>
      </GridItem>
      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
        <GridItem xs={12} sm={12} md={12}>
          <p style={{ fontWeight: '400' }}>
            Exhibición secundaria (máx. 10 fotos)
          </p>
          {images
            .filter(image => image.type === 'secondary')
            .map(image => (
              <ImageUpload
                name={image.name}
                key={image.name}
                baseFile={image.file}
                basePreviewUrl={image.imagePreviewUrl}
                onChange={image => onChange(image, 'secondary')}
                onRemove={onRemove}
              />
            ))}
          {images.filter(image => image.type === 'secondary').length < 10 && (
            <ImageUpload
              onlyUpload
              onChange={image => addImage(image, 'secondary')}
            />
          )}
        </GridItem>
      </div>
    </div>
  );
}
