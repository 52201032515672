const coverages = [
  { clientId: 9, branchId: 'INC1', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'INC2', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'INC5', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC6', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'INC7', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC8', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC10', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC11', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC12', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC16', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC18', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC20', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC21', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC23', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC25', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'INC26', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC28', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC30', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC32', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC33', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC36', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC40', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC42', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC43', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC47', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC49', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC51', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC62', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC101', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC115', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC116', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC118', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC120', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC150', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC182', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC183', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC187', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC189', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC195', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC198', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC201', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC202', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC203', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC204', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC205', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC206', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC209', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC211', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC212', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC214', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC215', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC216', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC218', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC219', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC220', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC221', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC226', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC227', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC228', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC231', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC234', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC237', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC239', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC241', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC252', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC253', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC254', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC258', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC268', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC272', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC277', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC278', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'INC294', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'INC15', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'INC703', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC702', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC701', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC24', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC9', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC54', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC50', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC707', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC295', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC17', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC29', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC282', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC59', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC283', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC287', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'INC290', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'CHM1039', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1067', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT2', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT19', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT24', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT41', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT43', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT44', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT45', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT47', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT48', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT51', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT52', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT55', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT56', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT57', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT58', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT60', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT61', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT63', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT64', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT65', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT66', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT67', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT68', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT69', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT74', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT75', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT78', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT80', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT82', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT83', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT84', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT85', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT90', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT91', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'COT92', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT95', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT96', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT97', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT99', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT101', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT103', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT105', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT107', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT108', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT109', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'COT111', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT118', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'COT124', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT129', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT131', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT149', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT151', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT153', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT158', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT160', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT163', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT165', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT168', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT170', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT175', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT176', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT179', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT181', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT183', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT184', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'COT186', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT188', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT189', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT192', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT197', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT203', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT208', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT215', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT219', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'COT220', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT235', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'COT209', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DAR1', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'DAR2', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'DAR3', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'DAR4', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'DIS1', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS3', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS4', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'DIS12', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS14', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS15', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS16', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'DIS23', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS25', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'DIS30', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS37', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS38', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS39', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS46', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'DIS48', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS54', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS107', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS111', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS152', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS154', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS235', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'DIS237', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS247', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS630', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS701', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS977', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS441', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS583', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS444', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS29', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS435', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS451', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS28', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS468', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS709', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS436', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS490', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS452', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS450', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS233', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS33', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA32', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'DIS60', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'DIS460', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'TNL2', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'TNL3', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'TNL4', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'TNL5', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'TNL7', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'TNL8', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'TNL9', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'JBO5626', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO662', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5260', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5201', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'JBO5202', frequency: 5, intensity: 1 },
  { clientId: 9, branchId: 'JBO5204', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5205', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'JBO5206', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'JBO5208', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'JBO5207', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5211', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'JBO5214', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5231', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5218', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5034', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5213', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5259', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO5263', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO628', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO138', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO121', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO182', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'JBO183', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'KIL3', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'KIL4', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'KIL8', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'LIB102', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB901', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB701', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1001', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB602', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB802', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1103', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1202', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1302', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1402', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1502', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB2802', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB2502', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB202', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'LIB1205', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'MKO1', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'MKO2', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO3', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'MKO6', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO8', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO9', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO10', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'MKO15', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO25', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO4', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO21', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO18', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO26', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO5', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'MKO13', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'MBS1', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'NIN1', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA153', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA155', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA158', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA162', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA633', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA692', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA697', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA698', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA636', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA6', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'VEA10', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA240', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA696', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA700', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA741', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA22', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA119', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA437', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA434', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA114', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA475', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA466', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA455', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA479', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'VEA476', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'VEA432', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA472', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA479', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA195', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA113', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA463', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA943', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'VEA187', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA399', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA68', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA1', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA189', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA180', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA398', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA192', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA855', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA190', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA184', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA66', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA183', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA15', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA220', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA5', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA179', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA34', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA63', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA14', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA3', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA21', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA175', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA25', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA53', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA55', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA50', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'VEA5', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1014', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1016', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1017', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1018', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1043', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT2997', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'WMT2999', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'WMT3601', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'WMT3603', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'WMT3604', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'WMT1010', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT3605', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT3606', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT3613', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1005', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'CHM1028', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1110', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1059', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'CHM1045', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1050', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1076', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1032', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1119', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1029', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1036', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'CHM1037', frequency: 1, intensity: 1 },
  { clientId: 9, branchId: 'CHM1013', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1096', frequency: 2, intensity: 1 },
  { clientId: 9, branchId: 'WMT1087', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'WMT1004', frequency: 4, intensity: 1 },
  { clientId: 9, branchId: 'WMT2998', frequency: 3, intensity: 1 },
  { clientId: 9, branchId: 'MKO23', frequency: 2, intensity: 1 }
];

export default coverages;
