import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { whiteColor, hexToRgb } from '../../../assets/jss/trade-app.js';

const useStyles = makeStyles(theme => ({
  ...hexToRgb,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300
  },
  buttonSelected: {
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      },
      '& $imageMarked': {
        opacity: 0
      },
      '& $imageTitle': {
        border: '4px solid currentColor'
      }
    }
  },
  image: {
    opacity: 0.8,
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      },
      '& $imageMarked': {
        opacity: 0
      },
      '& $imageTitle': {
        border: '4px solid currentColor'
      }
    }
  },
  focusVisible: {
    backgroundColor: 'rgba(' + hexToRgb(whiteColor) + ', 0.2)',
    transition: '0.2s background-color 0.1s'
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid currentColor',
    color: '#eeee'
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.9,
    transition: theme.transitions.create('opacity')
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    fontSise: 50,
    fontWeight: '400'
  },
  imageMarked: {
    height: 3,
    width: 25,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity')
  }
}));

export default function ButtonBases({ showFilters, type }) {
  const classes = useStyles();

  //console.log(type)

  const images = [
    {
      url:
        'https://firebasestorage.googleapis.com/v0/b/chek-app.appspot.com/o/images%2FSanIgnacio%2FSanIgnacio_2.jpg?alt=media&token=c54de3ef-ad5a-49bb-94e2-eaebb2fe835a',
      title: 'Destacadas',
      width: '25%',
      value: 'favorites'
    },
    {
      url:
        'https://firebasestorage.googleapis.com/v0/b/chek-app.appspot.com/o/images%2FSanIgnacio%2FSanIgnacio_4.jpg?alt=media&token=3f8048fd-b8ea-4fe3-9479-148e2564c6f6',
      title: 'Todas',
      width: '25%',
      value: 'all'
    },
    {
      url:
        'https://firebasestorage.googleapis.com/v0/b/chek-app.appspot.com/o/images%2FSanIgnacio%2FSanIgnacio_3.jpg?alt=media&token=3a731e23-700a-4688-bddf-f3813cc2ac1d',
      title: 'Pendientes',
      width: '25%',
      value: 'pendings'
    },
    {
      url:
        'https://firebasestorage.googleapis.com/v0/b/chek-app.appspot.com/o/images%2FSanIgnacio%2FSanIgnacio_1.jpg?alt=media&token=a4cd8bbb-8964-44e8-b9b8-fffc6a15350b',
      title: 'Revisadas',
      width: '25%',
      value: 'revised'
    }
  ];

  return (
    <>
      {images.map(image => (
        <ButtonBase
          //focusRipple
          key={image.title}
          className={[
            classes.image,
            {
              selected: classes.buttonSelected
            }
          ]}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
            zIndex: type == image.value && 2,
            opacity: type == image.value && '1'
          }}
          onClick={() => {
            showFilters(image.value);
          }}
        >
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`
            }}
          />
          <span
            className={classes.imageBackdrop}
            style={{
              backgroundColor: type == image.value ? 'inherit' : 'black'
            }}
          />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {image.title}
              <span className={type == image.value && classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      ))}
    </>
  );
}
