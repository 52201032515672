import { useState, useEffect } from 'react';
import Table from './Table';
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import { primaryBackgroundText } from '../../../../assets/jss/trade-app';

export const VisitsCardDetail = ({ classes }) => {
  const history = useHistory();
  const {
    chain,
    client,
    allBranches,
    allChains,
    zones,
    branchesXClient,
    branchesXChain,
    reportsXClient,
    reportsXChain,
    frequencyReport
  } = useFilters();


  //console.log(reportsXClient)
  const [frequencyString, setFrequencyString] = useState('');

  const getFrequencyString = () => {
    if (frequencyReport) {
      switch (frequencyReport) {
        case 'FV':
          setFrequencyString('Foto Visita');
          break;
        case 'FS':
          setFrequencyString('Foto Semanal');
          break;
        case 'FQ':
          setFrequencyString('Foto Quincenal');
          break;
        case 'FM':
          setFrequencyString('Foto Mensual');
          break;
        default:
          console.log('Error');
      }
    }
  };
  const [dataChain, setDataChain] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const columnsXChain = [
    {
      title: 'Sucursal',
      field: 'branch'
    },
    {
      title: 'Fecha de último reporte',
      field: 'lastReport'
    },
    {
      title: 'Zona',
      field: 'zone'
    },
    {
      title: 'Reporte Completo',
      field: 'isComplete'
    }
  ];

  const columnsXClient = [
    {
      title: 'Cadena',
      field: 'chain'
    },
    {
      title: 'Sucursal',
      field: 'branch'
    },
    {
      title: 'Último registro',
      field: 'lastReport'
    },
    {
      title: 'Zona',
      field: 'zone'
    },
    {
      title: 'Reporte Completo',
      field: 'isComplete'
    }
  ];


 
  useEffect(() => {
    getFrequencyString();
    if (chain.ID) {
      let reportsCh = [];
      const getReportsXChain = async () => {
          try {
            reportsXChain.map(async report => {
              //console.log(reportsXChain)
              let nReport = {
                branch: `${
                  allBranches.filter(b => b.ID == report.branchId)[0]?.name
                }`,
                lastReport: `${report.createdAt.toDate().toLocaleString()}`,
                zone: `${
                  zones.filter(z => z.ID == (allBranches.filter(b => b.ID == report.branchId)[0]?.zoneId))[0]?.name}`,
            
              isComplete:
                  report.isComplete ? 'SI' : 'NO'
            }
              console.log(nReport);
              reportsCh.push(nReport);
            });

          } catch (error) {
            console.error(`getReports. Ocurrió el error: ${error}`);
          }
      };
      getReportsXChain();
      setDataChain(reportsCh);
      //console.log('dataChain', dataChain);
    } else {
      let reportsCli = [];
      const getReportsXClient = async () => {
        try {
          reportsXClient.map(async report => {
            let nReport = {
              chain: `${allChains.filter(c => c.ID == report.branchId.substr(0, 3))[0]?.name}`,
              branch: `${allBranches.filter(b => b.ID == report.branchId)[0]?.name}`,
              lastReport: `${report.createdAt.toDate().toLocaleString()}`,
              zone: `${zones.filter(z => z.ID == (allBranches.filter(b => b.ID == report.branchId)[0]?.zoneId))[0]?.name}`,
              isComplete:
              report.isComplete ? 'SI' : 'NO'
            };
            reportsCli.push(nReport);
          });
        } catch (error) {
          console.error(`getReports. Ocurrió el error: ${error}`);
        }
      };
      getReportsXClient();
      setDataClient(reportsCli);
      //console.log('dataClient', dataClient);
    }
  }, [client, chain, branchesXClient]);


  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <h3>Tiendas con fotos</h3>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <div style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <p>
              Período de reporte fotográfico acordado: {'  '}
              <span style={primaryBackgroundText}>{`${frequencyString}`}</span>
            </p>
          </div>
          <br />
          <div style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <p>
              Número de tiendas con fotografías durante el período acordado:{' '}
              {'  '}
              <span style={primaryBackgroundText}>
                {chain.ID
                  ? `${reportsXChain.length} (de ${branchesXChain.length} totales)`
                  : `${reportsXClient.length} (de ${branchesXClient.length} totales)`}
              </span>
            </p>
          </div>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {chain.ID ? (
                <>
                  {reportsXChain.length > 0 && (
                    <>
                      <div className={classes.stats}>
                        <Link
                          href="#"
                          underline="hover"
                          color="primary"
                          variant="body1"
                          align="center"
                          onClick={() => history.push('/admin/revision')}
                        >
                          <p>Ver fotos</p>
                        </Link>
                      </div>
                      <br />
                      <Table
                        title="Tiendas Visitadas"
                        data={dataChain}
                        columns={columnsXChain}
                      />
                    </>
                  )}
                </>
              ) : (
                reportsXClient.length > 0 && (
                  <>
                    <div className={classes.stats}>
                      <Link
                        href="#"
                        underline="hover"
                        color="primary"
                        variant="body1"
                        align="center"
                        onClick={() => history.push('/admin/revision')}
                      >
                        <p>Ver fotos</p>
                      </Link>
                    </div>
                    <br />
                    <br />
                    <Table
                      title="Tiendas Contratadas"
                      data={dataClient}
                      columns={columnsXClient}
                    />
                  </>
                )
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};