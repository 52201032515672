import { useState } from 'react';
// core components
import GridItem from '../../../components/Grid/GridItem';
import { useFilters } from '../../../api/filters/FiltersProvider';
// core components
import Button from '../../../components/CustomButtons/Button.js';
import GridContainer from '../../../components/Grid/GridContainer';
// @material-ui/core components
import AddReport from './AddReport';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// styles
import { useFirebase } from '../../../Firebase';
import drop from '../../../assets/jss/trade-app/customSelectStyle';

const useStyles = makeStyles(theme => ({
  ...drop,
  root: {
    width: '100%'
  },
  heading1: {
    marginLeft: '15px',
    fontSize: '25px',
    color: 'gray',
    fontWeight: 'lighter'
  },
  heading2: {
    marginLeft: '15px',
    fontSize: '20px',
    color: 'gray',
    fontWeight: 'lighter',
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: '15px',
    color: 'gray'
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center',
    padding: '20px',
    '&:hover': {
      backgroundColor: 'primary'
    }
  },
  // column: {
  //   flexBasis: '25%',
  // },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function FilterBar({ type }) {
  const classes = useStyles();
  const {
    setBranch,
    setChain,
    setCategory,
    branch,
    chain,
    chainsWithReports,
    branchesWithReports,
    branchesXClient,
    category,
    categories
  } = useFilters();
  const [expanded, setExpanded] = useState(false);
  const [selectPeriod, setSelectPeriod] = useState('Semana');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const { firebase } = useFirebase();

  const handleSelectedFilters = () => {
    const selected = [];
    chain != '' ? selected.push(chain) : null;
    branch != '' ? selected.push(branch) : null;
    category != '' ? selected.push(category) : null;
    selectPeriod != '' ? selected.push(selectPeriod) : null;
    setSelectedFilters(selected);
  };
  const onDelete = pos => {
    setSelectedFilters(selectedFilters.splice(pos, 1));
  };

  const handleSelectChain = event => {
    setBranch({});
    const c = chainsWithReports.find(chain => chain.ID === event.target.value);
    setChain(c);
    handleSelectedFilters();
  };
  const handleSelectBranch = event => {
    const { value } = event.target;
    const branch = chain
      ? branchesWithReports.find(b => b.ID === value)
      : branchesXClient.find(b => b.ID === value);
    setBranch(branch);
    handleSelectedFilters();
  };
  const handleSelectCategory = event => {
    const { value } = event.target;
    const category = categories.find(category => category.ID === value);
    if (!category) setCategory({ name: 'Todas' });
    else setCategory(category);

    handleSelectedFilters();
  };
  const handleSelectPeriod = event => {
    setSelectPeriod(event.target.value);
    handleSelectedFilters();
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const openNewReport = () => {
    setOpenModalAdd(true);
  };

  const closeNewReport = () => {
    setOpenModalAdd(false);
  };
  const saveReport = async data => {
    //logica de guardado
    const categories = await Promise.all(
      data.categories.map(async category => {
        if (!category.images) return category;

        const images = await Promise.all(
          category.images.map(async image => {
            const task = firebase.storage
              .ref('reports')
              .child(firebase.auth.currentUser.uid)
              .child(image.name);

            await task.putString(image.uri, 'data_url');

            const uri = await task.getDownloadURL();

            return {
              ...image,
              uri
            };
          })
        );

        return {
          ...category,
          images
        };
      })
    );

    const report = await firebase.db.collection('reports').add({
      ...data,
      categories
    });

    console.log((await report.get()).data());
  };

  return (
    <div style={{ justifyContent: 'center' }}>
      <GridContainer>
        <AddReport
          open={openModalAdd}
          setClose={closeNewReport}
          save={saveReport}
          onFinish={closeNewReport}
        />
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-chain"
                  className={classes.selectLabel}
                >
                  Cadena
                </InputLabel>
                <Select
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  value={chain.ID ?? ''}
                  classes={{
                    select: classes.select
                  }}
                  onChange={handleSelectChain}
                  inputProps={{
                    name: 'selectChain',
                    id: 'select-chain'
                  }}
                >
                  <MenuItem value={''} disabled>
                    Seleccione cadena
                  </MenuItem>
                  {chainsWithReports.map(chain => {
                    return (
                      <MenuItem
                        key={chain.ID}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={chain.ID}
                      >
                        {chain.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-chain"
                  className={classes.selectLabel}
                >
                  Sucursal
                </InputLabel>
                <Select
                  disabled={chain ? false : true}
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  value={branch.ID ?? ''}
                  classes={{
                    select: classes.select
                  }}
                  onChange={handleSelectBranch}
                  inputProps={{
                    name: 'selectBranch',
                    id: 'select-branch'
                  }}
                >
                  <MenuItem value={''} disabled>
                    Seleccione sucursal
                  </MenuItem>
                  {chain
                    ? branchesWithReports.map(branch => {
                        return (
                          <MenuItem
                            key={branch.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={branch.ID}
                          >
                            {branch.name}
                          </MenuItem>
                        );
                      })
                    : branchesXClient.map(branch => {
                        return (
                          <MenuItem
                            key={branch.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={branch.ID}
                          >
                            {branch.name}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Categoría
                </InputLabel>
                <Select
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={category.ID ?? ''}
                  onChange={handleSelectCategory}
                  inputProps={{
                    name: 'selectCategory',
                    id: 'select-category'
                  }}
                >
                  <MenuItem
                    // key={chain.ID}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={''}
                  >
                    Todas
                  </MenuItem>
                  {categories.map((category, i) => (
                    <MenuItem
                      key={category.ID}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={category.ID}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <p></p>
              <p></p>

              <Button
                color="primary"
                aria-haspopup="true"
                fullWidth
                onClick={openNewReport}
                className={{ margin: '15px' }}
              >
                Agregar nuevo reporte
              </Button>
              {/* <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="select-period"
                className={classes.selectLabel}
              >
                Período relevado
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={selectPeriod}
                onChange={handleSelectPeriod}
                inputProps={{
                  name: 'selectPeriod',
                  id: 'select-period'
                }}
              >
                {period.map((p, i) => {
                  return (
                    <MenuItem
                      key={i}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={period[i]}
                    >
                      {p}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
