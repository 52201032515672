import { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import GridList from '@material-ui/core/GridList';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridListTile from '@material-ui/core/GridListTile';
import { useFirebase } from '../../../Firebase';
import { format } from 'date-fns';
import { useFilters } from '../../../api/filters/FiltersProvider';

import { primaryColor } from '../../../assets/jss/trade-app';

import Tile from './Tile';

const styles = {
  gridList: {
    width: '80%',
    display: 'flex',
    paddingTop: '20px',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    cursor: 'pointer',
    paddingLeft: 30
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  date: {
    display: 'flex',
    width: '90%',
    fontSize: 18,
    fontWeight: '400',
    color: 'gray',
    marginTop: '30px'
  },
  iconCheck: {
    display: 'flex',
    width: '5%'
  },
  reportDate: {
    paddingTop: '20px',
    marginLeft: '15px',
    width: '15%',
    display: 'inline'
  },
  report: {
    flexDirection: 'row',
    width: '100%'
  }
};

const useStyles = makeStyles(styles);

export default function Report({
  report,
  category,
  disableAction,
  revisable = true
}) {
  const classes = useStyles();
  const [revised, setRevised] = useState(report.revised ?? false);
  const { firebase } = useFirebase();
  const { setReportsXClient } = useFilters();

  const handleRevised = async () => {
    await firebase.db.collection('reports').doc(report.id).update({
      revised: !revised
    });

    setReportsXClient(reports =>
      reports.map(r => (r.id === report.id ? { ...r, revised: !revised } : r))
    );
    setRevised(!revised);
  };

  return (
    <div>
      <div>
        <p className={classes.date}>
          {format(report.createdAt.toDate(), 'DD/MM/YYYY')}
        </p>
        {revisable && (
          <span style={{ display: 'flex' }}>
            <IconButton className={classes.iconCheck} onClick={handleRevised}>
              <Icon style={{ color: primaryColor[0] }}>
                {revised ? 'check_circle' : 'check_circle_outlined'}
              </Icon>
            </IconButton>
            <span
              style={{
                color: primaryColor[0],
                fontWeight: '400',
                marginTop: '13px'
              }}
            >
              {' '}
              Marcar todas las fotos de este reporte como revisadas{' '}
            </span>
          </span>
        )}
      </div>
      {report.categories.map((cat, catIndex) => (
        <GridContainer key={cat.ID} className={classes.report}>
          {!category.ID && (
            <div className={classes.reportDate}>
              <p>{cat.name}</p>
            </div>
          )}
          <GridList className={classes.gridList} cols={4}>
            {cat.images.map(tile => (
              <GridListTile key={tile.name}>
                <Tile
                  report={report}
                  disableAction={disableAction}
                  catIndex={catIndex}
                  tile={tile}
                />
              </GridListTile>
            ))}
          </GridList>
        </GridContainer>
      ))}
    </div>
  );
}
