import {
  BrowserRouter as Router,
  useHistory,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import * as ROUTES from './constants/routes';

// Layouts
import AuthLayout from './layouts/Auth.js';
import AdminLayout from './layouts/Admin.js';
import ClientLayout from './layouts/Client.js';
import SuperAdminLayout from './layouts/SuperAdmin.js';

// Components
import Can from './components/Can/Can.js';

// Styles
import './assets/scss/trade-app.scss';

// Material
import { esES } from '@material-ui/core/locale';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

// Firebase
import { FirebaseProvider } from './Firebase';

const App = () => {
  const history = useHistory();

  const theme = createMuiTheme(
    {
      palette: {
        primary: { main: '#F2692B' }
      }
    },
    esES
  );

  return (
    <Router history={history}>
      <FirebaseProvider>
        <ThemeProvider theme={theme}>
            <Switch>
              <Route path={ROUTES.L_AUTH} component={AuthLayout} />
              <Route path={ROUTES.L_ADMIN}>
                <Can action="dash-a" yes={<AdminLayout />} />
              </Route>
              <Route path={ROUTES.L_CLIENT}>
                <Can action="dash-c" yes={<ClientLayout />} />
              </Route>
              <Route path={ROUTES.L_SUPERADMIN}>
                <Can action="dash-s" yes={<SuperAdminLayout />} />
              </Route>
              <Redirect from={ROUTES.L_AUTH} to="/auth/login" />
              <Redirect from={ROUTES.L_ADMIN} to="/admin/dash-a" />
              <Redirect from={ROUTES.L_CLIENT} to="/client/dash-c" />
              <Redirect from={ROUTES.L_SUPERADMIN} to="sadmin/dash-s" />
              <Redirect from="/" to="/auth/home" />
            </Switch>
        </ThemeProvider>
      </FirebaseProvider>
    </Router>
  );
};

export default App;
