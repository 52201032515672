import { useState } from 'react';

import { useClient } from '../../../api/useClient';
import { useClientsProvider } from '../../../api/filters/ClientsProvider';
import { makeStyles } from '@material-ui/core/styles';
import drop from '../../../assets/jss/trade-app/customSelectStyle.js';

//core components
import InputLabel from '@material-ui/core/InputLabel';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';

// material UI components
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  ...drop,
  root: {
    width: '100%',
    flexDirection: 'column'
  },
  heading1: {
    marginLeft: '15px',
    fontSize: '25px',
    color: 'gray',
    fontWeight: 'lighter'
  },
  heading2: {
    marginLeft: '15px',
    fontSize: '20px',
    color: 'gray',
    fontWeight: 'lighter',
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: '15px',
    color: 'gray'
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center',
    padding: '20px',
    '&:hover': {
      backgroundColor: 'primary'
    }
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const period = ['Semana', 'Quincena ', 'Mes'];

export default function FilterBar() {
  const { client } = useClient();
  const classes = useStyles();
  const {
    setBranch,
    setClient,
    setChain,
    getChains,
    getBranches,
    setCategory,
    getBranchesxClient,
    getCategoriesxClient,
    branchesXClient,
    categoriesXClient,
    branch,
    chain,
    branches,
    chains,
    category,
    chainsWithReports,
    branchesWithReports,
    categories
  } = useClientsProvider();

  const [selectPeriod, setSelectPeriod] = useState('Semana');
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleSelectedFilters = () => {
    const selected = [];
    chain != '' ? selected.push(chain) : null;
    branch != '' ? selected.push(branch) : null;
    category != '' ? selected.push(category) : null;
    selectPeriod != '' ? selected.push(selectPeriod) : null;

    setSelectedFilters(selected);
  };

  const onDelete = pos => {
    setSelectedFilters(selectedFilters.splice(pos, 1));
  };

  const handleSelectChain = event => {
    setBranch({});
    const c = chainsWithReports.find(chain => chain.ID === event.target.value);
    setChain(c);
    handleSelectedFilters();
  };
  const handleSelectBranch = event => {
    const { value } = event.target;
    const branch = chain
      ? branchesWithReports.find(b => b.ID === value)
      : branchesXClient.find(b => b.ID === value);
    setBranch(branch);
    handleSelectedFilters();
  };
  const handleSelectCategory = event => {
    const { value } = event.target;
    const category = categories.find(category => category.ID === value);
    if (!category) setCategory({ name: 'Todas' });
    else setCategory(category);

    handleSelectedFilters();
  };
  const handleSelectPeriod = event => {
    setSelectPeriod(event.target.value);
    handleSelectedFilters();
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-chain"
                  className={classes.selectLabel}
                >
                  Cadena
                </InputLabel>
                <Select
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={chain.ID ?? ''}
                  onChange={handleSelectChain}
                  inputProps={{
                    name: 'selectChain',
                    id: 'select-chain'
                  }}
                >
                  <MenuItem value={''} disabled>
                    Seleccione cadena
                  </MenuItem>

                  {chainsWithReports.map(chain => {
                    return (
                      <MenuItem
                        key={chain.ID}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={chain.ID}
                      >
                        {chain.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-branch"
                  className={classes.selectLabel}
                >
                  Sucursal
                </InputLabel>
                <Select
                  disabled={chain ? false : true}
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={branch.ID ?? ''}
                  onChange={handleSelectBranch}
                  inputProps={{
                    name: 'selectBranch',
                    id: 'select-branch'
                  }}
                >
                  <MenuItem value={''} disabled>
                    Seleccione sucursal
                  </MenuItem>
                  {branchesWithReports.map(branch => (
                    <MenuItem
                      key={branch.ID}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={branch.ID}
                    >
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-category"
                  className={classes.selectLabel}
                >
                  Categoría
                </InputLabel>
                <Select
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={category.ID ?? ''}
                  onChange={handleSelectCategory}
                  inputProps={{
                    name: 'selectCategory',
                    id: 'select-branch'
                  }}
                >
                  <MenuItem
                    key={branch.ID}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={''}
                  >
                    Todas las categorías
                  </MenuItem>
                  {categories.map((category, i) => (
                    <MenuItem
                      key={category.ID}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={category.ID}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
