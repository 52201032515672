import { useState, useEffect } from 'react';
import firebase from '../../../../Firebase';
import TableCrud from '../../../../components/Table/TableCrud';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import CustomLinearProgress from '../../../../components/CustomLinearProgress/CustomLinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SuccessAlert from '../../../../myComponents/SuccessAlert';

const GetBranches = () => {
  const { zones, allChains } = useFilters();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedIdentifier, setSelectedIdentifier] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedZone, setSelectedZone] = useState(null);
  const [create, setCreate] = useState(false);

  const onConfirm = () => {
    setCreate(false);
    getAllBranches();
  };

  const handleSelectChain = (_, value) => {
    setSelectedChain(value);
  };
  const handleSelectIdentifier = event => {
    event.preventDefault()
    setSelectedIdentifier(event.target.value);
  };
  const handleSelectName = event => {
    event.preventDefault()
    setSelectedName(event.target.value);
  };
  const handleSelectAddress = event => {
    event.preventDefault()
    setSelectedAddress(event.target.value);
  };
  const handleSelectZone = (_, value) => {
    setSelectedZone(value);
  };

  const getAllBranches = async () => {
    const result = await firebase.db
      .collection('branches')
      .orderBy('ID', 'asc')
      .get();

    const branches = result.docs.map(br => ({
      ...br.data(),
      id: br.id
    }));
    setBranches(branches);
  };

  const saveNewBranch = async () => {
    setLoading(true);
    await firebase.db
      .collection('branches')
      .add({
        ID: selectedIdentifier,
        name: selectedName,
        chainId: selectedChain.ID,
        chainName: selectedChain.name,
        address: selectedAddress,
        zoneId: selectedZone.ID
      })
      .then(docRef => {
        console.log('Document successfully written!', docRef.id);
        setCreate(true);
        setSelectedIdentifier('');
        setSelectedChain(null);
        setSelectedAddress('');
        setSelectedZone(null);
      })
      .catch(error => {
        console.error('Error writing document: ', error);
      });
      setLoading(false);
  };

  useEffect(() => {
    getAllBranches()
  }, []);

  const columns = [
    {
      title: 'Identificador',
      field: 'ID',
      validate: rowData =>
        rowData.ID === ''
          ? { isValid: false, helperText: 'Campo obligatorio' }
          : true,
      editComponent: () => (
        <TextField
          InputLabelProps={{
            shrink: true
          }}
          id="selectedIdentifier"
          variant="standard"
          label="Identificador"
          value={selectedIdentifier}
          onChange={handleSelectIdentifier}
          helperText="ID Cadena + N° Sucursal"
        />
      )
    },
    {
      title: 'Nombre',
      field: 'name',
      validate: rowData =>
        rowData.name === '' || rowData.name?.length > 3
          ? { isValid: false, helperText: 'Revise este campo' }
          : true,
      editComponent: props => (
        <TextField
          InputLabelProps={{
            shrink: true
          }}
          id="selectedName"
          variant="standard"
          label="Nombre"
          value={selectedName}
          onChange={handleSelectName}
          helperText="Nombre de la sucursal"
        />
      )
    },
    {
      title: 'Cadena',
      field: 'chainName',
      validate: rowData =>
        rowData.chainId === ''
          ? { isValid: false, helperText: 'Campo obligatorio' }
          : true,
      editComponent: props => (
        <Autocomplete
          disableClearable
          id="selectedChain"
          options={allChains}
          value={selectedChain}
          getOptionLabel={option => option.name}
          onChange={handleSelectChain}
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
              label="Cadena"
              helperText="Cadena de la sucursal"
            />
          )}
        />
      ),
      initialEditValue: rowData => rowData.chainName
    },
    {
      title: 'Dirección',
      field: 'address',
      editComponent: props => (
        <TextField
          InputLabelProps={{
            shrink: true
          }}
          id="selectedAddress"
          variant="standard"
          label="Dirección"
          value={selectedAddress}
          onChange={handleSelectAddress}
          helperText="Calle y numeración"
        />
      )
    },
    {
      title: 'Zona',
      field: 'zoneId',
      validate: rowData =>
        rowData.zoneId === ''
          ? { isValid: false, helperText: 'Campo obligatorio' }
          : true,
      render: rowData => `${zones.filter(z => z.ID == rowData.zoneId)[0]?.name}`,  
      editComponent: props => {
        return (
          <Autocomplete
            disableClearable
            id="selectedZone"
            options={zones}
            value={selectedZone}
            getOptionLabel={option => option.name}
            onChange={handleSelectZone}
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true
                }}
                variant="standard"
                label="Zona"
                id="zone"
                helperText="Zona geográfica"
              />
            )}
          />
        );
      }
    }
  ];

  return (
    <>
        {create ? (
          <SuccessAlert msg="Sucursal creada con éxito" onConfirm={onConfirm} />
        ) : null}
        <>
          {data && (
            <div>
              <TableCrud
                title="Sucursales"
                data={branches}
                columns={columns}
                isLoading={loading}
                editable={{
                  onRowAdd: () =>
                    new Promise(resolve => {
                      saveNewBranch();
                      resolve();
                    }),
                  // onRowUpdate: (newData, oldData) =>
                  //   new Promise(resolve => {
                  //       firebase.db
                  //         .collection('branches')
                  //         .doc(`${oldData.id}`)
                  //         .set({
                  //           ID: newData.ID,
                  //           name: newData.name,
                  //           chainName: newData.chain.name,
                  //           chainId: newData.chain.ID,
                  //           address: newData.address,
                  //           zoneId: newData.zoneId,
                  //         })
                  //         .then(function () {
                  //           console.log('Document successfully written!');
                  //           getBranches();
                  //         })
                  //         .catch(function (error) {
                  //           console.error('Error writing document: ', error);
                  //         });
                  //       resolve();
                  //   }),

                  onRowDelete: oldData =>
                    firebase.db
                      .collection('branches')
                      .doc(`${oldData.id}`)
                      .delete()
                      .then(function () {
                        console.log('Document successfully deleted!');
                        getAllBranches();
                      })
                      .catch(function (error) {
                        console.error('Error removing document: ', error);
                      })
                }}
              />
            </div>
          )}
        </>
    </>
  );
};

export default GetBranches;
