import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ConfirmRevision = ({ handleClose, isOpen }) => {
  return (
    <>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmar revisión</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Recuerda que al confirmar la revisión los clientes que requieren de
            un control podrán comenzar a ver las fotos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="transparent" onClick={handleClose}>
            Cerrar
          </Button>
          <Button color="primary" onClick={handleClose}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmRevision;
