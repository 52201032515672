import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const ForgotPassword = ({handleClose, isOpen}) => {
    
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Restablecer contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escriba a <b>soporte.chek@gmail.com</b> para solicitar el restablecimiento de
            su contraseña
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ForgotPassword;
