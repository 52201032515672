import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ContactPage from "../Contact/ContactPage"

const Contact = ({handleClose, isOpen}) => {
    
  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
          <div style={{alignItems: "row"}}>
        <DialogTitle id="form-dialog-title">Formulario de contacto
        </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText>
            <ContactPage closeModal={handleClose}/>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Contact;
