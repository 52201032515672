import { useState, useEffect } from 'react';
import TableCrud from '../../../../components/Table/TableCrud';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import { useFirebase } from '../../../../Firebase';
import periodReport from '../../../../data/periodReport';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SuccessAlert from '../../../../myComponents/SuccessAlert';

const GetCoverages = () => {
  const { coverages, allClients, allBranches, allChains } = useFilters();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { firebase } = useFirebase();
  const {
    clientsData,
    branch,
    chains,
    branchesXChain,
    setChain,
    setClient,
    setBranch,
    setCoverages
  } = useFilters();
  const [coverageClient, setCoverageClient] = useState(null);
  const [coverageChain, setCoverageChain] = useState(null);
  const [coverageBranch, setCoverageBranch] = useState(null);
  const [coverageFrequency, setCoverageFrequency] = useState('');
  const [coverageIntensity, setCoverageIntensity] = useState('');
  const [coveragePeriodReport, setCoveragePeriodReport] = useState('');
  const [create, setCreate] = useState(false);

  const onConfirm = () => {
    setCreate(false);
    refreshCoverages();
  };

  const handleSelectClient = (_, value) => {
    setChain({})
    setCoverageChain(null);
    setBranch({});
    setCoverageBranch(null);
    setCoverageClient(value);
    setClient(value ? value.ID : '');
  };

  const handleSelectChain = (_, value) => {
    setBranch({});
    setCoverageBranch(null);
    setCoverageChain(value);
    setChain(value ?? {});
  };

  const handleSelectBranch = (_, value) => {
    setCoverageBranch(value);
    setBranch(value ?? {});
  };

  const handleSelectFrequency = event => {
    setCoverageFrequency(event.target.value);
  };

  const handleSelectIntensity = event => {
    setCoverageIntensity(event.target.value);
  };

  const handleSelectPeriodReport = (_, value) => {
    setCoveragePeriodReport(value);
  };

  const refreshCoverages = async () => {
    try {
      let data = [];
      let result = await firebase.db
        .collection('coverages')
        .orderBy('clientId')
        .get();
      data = result.docs.map(aCov => {
        return {
          ...aCov.data(),
          id: aCov.id
        };
      });
      setCoverages(data);
      return data;
    } catch (error) {
      console.error(`refreshCoverages. Ocurrió el error: ${error}`);
    }
  };

  const saveNewCoverage = async () => {
    setLoading(true);
    if (
      coverageClient &&
      coverageChain &&
      coverageBranch &&
      coverageFrequency &&
      coverageIntensity &&
      coveragePeriodReport
    ) {
      await firebase.db
        .collection('coverages')
        .add({
          clientId: coverageClient.ID,
          chainId: coverageChain.ID,
          branchId: coverageBranch.ID,
          frequency: coverageFrequency,
          intensity: coverageIntensity,
          periodReport: coveragePeriodReport.ID
        })
        .then(docRef => {
          console.log('Document successfully written!', docRef.id);
          setCreate(true);
          setCoverageClient('');
          setCoverageChain('');
          setCoverageBranch('');
          setCoverageFrequency('');
          setCoverageIntensity('');
          setCoveragePeriodReport('');
        })
        .catch(error => {
          console.error('Error writing document: ', error);
        });
    } else {
      alert('Debe completar todos los campos');
    }
    setLoading(false);
  };

  const frequencyString = [
    {
      ID: 'FV',
      name: 'Foto Visita'
    },
    {
      ID: 'FS',
      name: 'Foto Semanal'
    },
    {
      ID: 'FQ',
      name: 'Foto Quincenal'
    },
    {
      ID: 'FM',
      name: 'Foto Mensual'
    },
    {
      ID: 'IN',
      name: 'Inactivo'
    }
  ];

  const columns = [
    {
      title: 'Cliente',
      field: 'client',
      validate: rowData => (rowData.client === '' ? 'Campo obligatorio' : ''),
      editComponent: props => (
        <Autocomplete
          disableClearable
          id="client"
          options={clientsData}
          value={coverageClient}
          getOptionLabel={option => option.name}
          onChange={handleSelectClient}
          defaultValue={clientsData[0]}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Cliente"
              InputLabelProps={{
                shrink: true
              }}
              helperText="Cliente del anexo"

            />
          )}
        />
      )
    },
    {
      title: 'Cadena',
      field: 'chain',
      validate: rowData => (rowData.chain === '' ? 'Campo obligatorio' : ''),
      editComponent: props => (
        <Autocomplete
          disableClearable
          id="chain"
          disabled={!coverageClient}
          options={chains}
          value={coverageChain}
          getOptionLabel={option => option.name}
          onChange={handleSelectChain}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Cadena"
              InputLabelProps={{
                shrink: true
              }}
              helperText="Cadena del anexo"
            />
          )}
        />
      )
    },
    {
      title: 'Sucursal',
      field: 'branch',
      validate: rowData => (rowData.branch === '' ? 'Campo obligatorio' : ''),
      initialEditValue: rowData => rowData.branch === '',
      editComponent: props => (
        <Autocomplete
          disableClearable
          disabled={!coverageChain}
          id="branch"
          value={coverageBranch}
          options={branchesXChain}
          getOptionLabel={option => option.name}
          onChange={handleSelectBranch}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label="Sucursal"
              InputLabelProps={{
                shrink: true
              }}
              helperText="Punto de venta"
            />
          )}
        />
      )
    },
    {
      title: 'Frecuencia',
      field: 'frequency',
      type: 'numeric',
      validate: rowData =>
        rowData.frequency === '' ? 'Campo obligatorio' : '',
      initialEditValue: rowData => rowData.frequency === 8,

      editComponent: props => (
        <TextField
          variant="standard"
          label="Frecuencia"
          id="frequency"
          type="number"
          value={coverageFrequency}
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleSelectFrequency}
          helperText="Días a la semana"

        />
      )
    },
    {
      title: 'Intensidad',
      field: 'intensity',
      type: 'numeric',
      editComponent: props => (
        <TextField
          variant="standard"
          label="Intensidad"
          id="intensity"
          type="number"
          value={coverageIntensity}
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleSelectIntensity}
          helperText="Horas en visita"
        />
      )
    },
    {
      title: 'PRA',
      field: 'periodReport',
      editComponent: props => (
        <Autocomplete
          disableClearable
          id="periodReport"
          value={coveragePeriodReport}
          options={periodReport}
          getOptionLabel={option => option.name}
          onChange={handleSelectPeriodReport}
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
              label="PRA"
              helperText="Período reporte"
            />
          )}
        />
      )
    }
  ];

  useEffect(() => {
    let covs = [];
    const getCoverages = async () => {
      try {
        setLoading(true);
        coverages.map(async coverage => {
          let nCoverage = {
            client: `${
              allClients.filter(c => c.ID == coverage.clientId)[0]?.name
            }`,
            chain: `${
              allChains.filter(ch => ch.ID == coverage.branchId.substr(0, 3))[0]
                ?.name
            }`,
            branch: `${
              allBranches.filter(b => b.ID == coverage.branchId)[0]?.name
            }`,
            intensity: coverage.intensity,
            frequency: coverage.frequency,
            periodReport:
              `${
                frequencyString.filter(pr => pr.ID == coverage.periodReport)[0]
                  ?.name
              }` == 'undefined'
                ? 'No definido'
                : `${
                    frequencyString.filter(
                      pr => pr.ID == coverage.periodReport
                    )[0]?.name
                  }`,
            id: coverage.id
          };
          covs.push(nCoverage);
        });
        setLoading(false);
      } catch (error) {
        console.error(`getCoverages. Ocurrió el error: ${error}`);
      }
    };
    setData(covs);
    getCoverages();
  }, [coverages]);

  return (
    <>
      {create ? (
        <SuccessAlert msg="Anexo agregado con éxito" onConfirm={onConfirm} />
      ) : null}
      {data && (
        <div>
          <TableCrud
            title="Anexos"
            data={data}
            columns={columns}
            isLoading={loading}
            editable={{
              onRowAdd: () =>
                new Promise(resolve => {
                  saveNewCoverage();
                  resolve();
                }),
              // onRowUpdate: (newData, oldData) =>
              //   new Promise((resolve) => {
              //       firebase.db
              //         .collection('coverages')
              //         .doc(`${oldData.id}`)
              //         .set({
              //           clientId: newData.client,
              //           chainId: newData.chain,
              //           branchId: newData.branch,
              //           frequency: newData.frequency,
              //           intensity: newData.intensity,
              //           periodReport: newData.periodReport,
              //         })
              //         .then(function () {
              //           console.log('Document successfully written!');
              //           refreshCoverages();
              //         })
              //         .catch(function (error) {
              //           console.error('Error writing document: ', error);
              //         });
              //       resolve();
              //   }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  firebase.db
                    .collection('coverages')
                    .doc(`${oldData.id}`)
                    .delete()
                    .then(function () {
                      refreshCoverages();
                      console.log('Document successfully deleted!');
                    })
                    .catch(function (error) {
                      console.error('Error removing document: ', error);
                    }),
                    resolve();
                })
            }}
          />
        </div>
      )}
    </>
  );
};

export default GetCoverages;
