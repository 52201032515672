import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";

import GetClients from "./Crud/GetClients";
import CreateClient from "./Crud/CreateClient";

import { AddCircle, ListAlt } from "@material-ui/icons";

export default function ClientCrud() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Clientes",
              tabIcon: ListAlt,
              tabContent: <GetClients />,
            },
            {
              tabName: "Nuevo Cliente",
              tabIcon: AddCircle,
              tabContent: <CreateClient />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
