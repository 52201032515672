import React, { useState, useEffect } from 'react';
import Table from './Table';
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import {
  primaryBackgroundText,
} from '../../../../assets/jss/trade-app';

const ChainsCardDetail = () => {
  const {
    chain,
    client,
    branchesXChain,
    branchesXClient,
    coverageXClient,
    allBranches,
    allChains,
    zones,
  } = useFilters();
  const [dataChain, setDataChain] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const columnsXChain = [
    {
      title: 'Sucursal',
      field: 'branch'
    },
    {
      title: 'Frecuencia',
      field: 'frequency'
    },
    {
      title: 'Intensidad',
      field: 'intensity'
    },
    {
      title: 'Zona',
      field: 'zone'
    }
  ];

  const columnsXClient = [
    {
      title: 'Cadena',
      field: 'chain'
    },
    {
      title: 'Sucursal',
      field: 'branch'
    },
    {
      title: 'Frecuencia',
      field: 'frequency'
    },
    {
      title: 'Intensidad',
      field: 'intensity'
    },
    {
      title: 'Zona',
      field: 'zone'
    }
  ];


  const getBranchesXChain = () => {
      try {
        let coverageXChain = coverageXClient.filter(
          cxcli => cxcli.branchId.substr(0, 3) == chain.ID
        );
        const dataChain = coverageXChain.map(coverage => {
          let nCoverage = {
            branch: `${
              allBranches.filter(b => b.ID == coverage.branchId)[0]?.name
            }`,
            intensity: coverage.intensity,
            frequency: coverage.frequency,
            zone: `${zones.filter(z => z.ID == allBranches
                          .filter(b => b.ID == coverage.branchId)[0]?.zoneId)[0]?.name}`
          };
          return nCoverage;
        });
        return dataChain;
      } catch (error) {
        console.error(`getBranchesXChain. Ocurrió el error: ${error}`);
      }
  };

  const getBranchesXClient = () => {
    try {
      const dataClient = coverageXClient.map(coverage => {
        let nCoverage = {
          chain: `${
            allChains.filter(c => c.ID == coverage.branchId.substr(0, 3))[0]?.name
           }`,
            branch: `${
              allBranches.filter(b => b.ID == coverage.branchId)[0]?.name
            }`,
            intensity: coverage.intensity,
            frequency: coverage.frequency,
            zone: `${
              zones.filter(
                z =>
                  z.ID ==
                  allBranches.filter(b => b.ID == coverage.branchId)[0]?.zoneId
              )[0]?.name
            }`
        };
        return nCoverage;
      });
      return dataClient;
    } catch (error) {
      console.error(`getBranchesXClient. Ocurrió el error: ${error}`);
    }
  };

  useEffect(() => {
    if (chain.name && chain.name != 'Todas') {
      const dataChain = getBranchesXChain();
      setDataChain(dataChain);
    } else {
      const dataClient = getBranchesXClient();
      setDataClient(dataClient);
    }
  }, [client, chain, branchesXClient, branchesXChain]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3>Tiendas contratadas</h3>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {chain.name && chain.name != 'Todas' ? (
            <>
              <br />
              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de tiendas totales contratadas en la cadena
                  seleccionada: {'  '}
                  <span
                    style={primaryBackgroundText}
                  >{`${branchesXChain.length}`}</span>
                </p>
                <br />
              </div>
              <Table
                title="Tiendas Contratadas"
                data={dataChain}
                columns={columnsXChain}
              />
              <br />
            </>
          ) : (
            <>
              <br />
              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de tiendas totales contratadas para todas las cadenas:{' '}
                  {'  '}
                  <span
                    style={primaryBackgroundText}
                  >{`${branchesXClient.length}`}</span>
                </p>
              </div>
              <br />
              <Table
                title="Tiendas Contratadas"
                data={dataClient}
                columns={columnsXClient}
              />
            </>
          )}
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ChainsCardDetail;
