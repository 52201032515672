/*eslint-disable*/
import React from "react";
//import PropTypes from "prop-types";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../assets/jss/trade-app/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

const SuccessAlert = ({ msg, onConfirm }) => {
  const classes = useStyles();

  return (
    <SweetAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="¡Buen trabajo!"
      onConfirm={onConfirm}
      onCancel={() => hideAlert()}
      confirmBtnCssClass={classes.button + " " + classes.success}
    >
      {msg}
    </SweetAlert>
  );
};

const hideAlert = () => {
  return null;
};

export default SuccessAlert;
