import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GridItem from '../../../components/Grid/GridItem.js';
import GridContainer from '../../../components/Grid/GridContainer.js';
import { useFilters } from '../../../api/filters/FiltersProvider';
import CategoryReportForm from './CategoryReportForm';
import { primaryColor } from '../../../assets/jss/trade-app';
import { useFirebase } from '../../../Firebase';
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appBar: {
    //position: 'relative',
    position: 'sticky'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 100,
    color: '#fff'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddReport({ open, setClose, save, onFinish }) {
  const classes = useStyles();
  const [reportClient, setReportClient] = useState(null);
  const { firebase } = useFirebase();
  const [reportChain, setReportChain] = useState(null);
  const [reportBranch, setReportBranch] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const _defaultDialogInformation = { title: '', message: '' };
  const [dialogInformation, setDialogInformation] = useState(
    _defaultDialogInformation
  );
  const {
    clientsData,
    branch,
    chains,
    branchesXChain,
    categories: categoriesBase,
    setChain,
    setClient,
    setBranch
  } = useFilters();
  const [openedCategories, setOpenedCategories] = useState([]);
  const [categories, setCategories] = useState(categoriesBase);

  const handleSelectClient = (_, value) => {
    setChain({});
    setReportChain(null);
    setBranch({});
    setReportBranch(null);
    setReportClient(value);
    setClient(value ? value.ID : {});
  };

  const handleSelectChain = (_, value) => {
    setBranch({});
    setReportBranch(null);
    setReportChain(value);
    setChain(value ?? {});
  };

  const handleSelectBranch = (_, value) => {
    setReportBranch(value);
    setBranch(value ?? {});
  };

  const handleOpenCategory = id => {
    if (openedCategories.includes(id)) {
      const opened = openedCategories.filter(category => category !== id);
      setOpenedCategories(opened);
    } else setOpenedCategories([...openedCategories, id]);

    console.log(openedCategories);
  };

  const errorNameByIndex = ['cliente', 'cadena', 'sucursal'];

  async function sendReport() {
    let lf = new Intl.ListFormat('es', {
      type: 'conjunction',
      style: 'long'
    });
    setLoading(true);

    if (!reportClient || !reportBranch || !reportChain) {
      var errorList = [reportClient, reportBranch, reportChain]
        .map((elm, index) => (elm ? null : errorNameByIndex[index]), [])
        .filter(element => element);

      setLoading(false);

      setDialogInformation({
        title: errorList.length > 1 ? 'Campos incompletos' : 'Campo incompleto',
        message: `Debes seleccionar ${lf.format(errorList)}`
      });
      return;
    }

    const categoryError = categories
      .map(category => {
        if (!category.images) return category.name;
      })
      .filter(element => element);

    if (categoryError.length === categories.length) {
      setLoading(false);
      setDialogInformation({
        title:
          categoryError.length > 1
            ? 'Categorías sin fotografías'
            : 'Categoría sin fotografías',
        message: `No subiste imágenes para ${lf.format(categoryError)}`
      });
      return;
    }

    const newCategories = categories.map(category => {
      if (!category.images) return category;
      const images = category.images?.map(image => ({
        name: image.name,
        uri: image.imagePreviewUrl,
        type: image.type,
        comment: ''
      }));

      return {
        ...category,
        images
      };
    });

    setCategories(categoriesBase);

    await save({
      clientId: reportClient.ID,
      branchId: reportBranch.ID,
      chainId: reportChain.ID,
      categories: newCategories,
      createdBy: firebase.auth.currentUser.uid,
      createdAt: new Date(),
      isComplete: categories.every(
        category => category.images && Array.isArray(category.images)
      )
    });

    setLoading(false);

    setDialogInformation({
      title: 'Subiste un reporte',
      message: 'El reporte fue enviado con éxito'
    });

    setClose();
    setChain({});
    setBranch({});
    onFinish();
  }

  const closeErrorDialog = () =>
    setDialogInformation(_defaultDialogInformation);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen
        open={open}
        onClose={setClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={setClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Nuevo reporte
            </Typography>
            <Button
              autofocus
              color="inherit"
              style={{ fontSize: '1.25rem' }}
              endIcon={<Icon>send</Icon>}
              onClick={sendReport}
            >
              Enviar reporte
            </Button>
          </Toolbar>
        </AppBar>
        <GridContainer justify="center" style={{ padding: 10 }}>
          <GridItem xs={10} sm={10} md={10}>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4 style={{ color: primaryColor[0] }}>
                  Seleccioná los datos para el reporte:
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Autocomplete
                  id="client"
                  options={clientsData}
                  value={reportClient}
                  getOptionLabel={option => option.name}
                  onChange={handleSelectClient}
                  defaultValue={clientsData[0]}
                  renderInput={params => (
                    <TextField {...params} variant="standard" label="Cliente" />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Autocomplete
                  id="chain"
                  disabled={!reportClient}
                  options={chains}
                  value={reportChain}
                  getOptionLabel={option => option.name}
                  onChange={handleSelectChain}
                  renderInput={params => (
                    <TextField {...params} variant="standard" label="Cadena" />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Autocomplete
                  disabled={!reportChain}
                  id="branch"
                  value={branch}
                  options={branchesXChain}
                  getOptionLabel={option => option.name}
                  onChange={handleSelectBranch}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Sucursal"
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <br />
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{ color: primaryColor[0] }}>
                      Subí fotografías por categorías:
                    </h4>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  {categories.map(category => (
                    <React.Fragment key={category.id}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ width: '100%' }}
                          onClick={() => handleOpenCategory(category.id)}
                        >
                          {category.name}
                        </Button>
                      </GridItem>
                      {openedCategories.includes(category.id) && (
                        <CategoryReportForm
                          setCategories={setCategories}
                          category={category}
                        />
                      )}

                      <br />
                      <br />
                    </React.Fragment>
                  ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
            {/* <PictureUpload /> */}
          </GridItem>
        </GridContainer>
      </Dialog>
      <Dialog open={dialogInformation.title} onClose={closeErrorDialog}>
        <DialogTitle onClose={closeErrorDialog}>
          {dialogInformation.title}
        </DialogTitle>
        <DialogContent>{dialogInformation.message}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeErrorDialog} color="primary">
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
