// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Footer from "../../../components/Footer/Footer.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Parallax from "../../../components/Parallax/Parallax.js";

import styles from "../../../assets/jss/trade-app/views/landingPageStyle.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Parallax filter>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <h1 className={classes.title}>
                Chequeá tu marca cuando y donde quieras.
              </h1>
              <h4>
                Consectetur incididunt est magna ex incididunt aliquip dolore
                aliquip. Excepteur magna velit amet labore magna cupidatat
                tempor et est occaecat amet ipsum incididunt consequat. Nostrud
                non amet pariatur cupidatat veniam consectetur duis aute quis in
                minim est officia. Incididunt incididunt sint ex ad ad commodo
                ut in cupidatat. Magna ea fugiat ex fugiat amet do aliquip
                incididunt esse id.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          {/* <ContactSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
