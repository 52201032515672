// @material-ui/icons
import { ListAlt } from "@material-ui/icons";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import GetBranches from "./GetTables/GetBranches";
import GetChains from "./GetTables/GetChains";

export default function RetailCrud() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Sucursales",
              tabIcon: ListAlt,
              tabContent: <GetBranches />,
            },
            {
              tabName: "Cadenas",
              tabIcon: ListAlt,
              tabContent: <GetChains />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
