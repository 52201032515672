// @material-ui/icons
import { ListAlt } from "@material-ui/icons";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import GetProducts from "./GetTables/GetProducts";

export default function ProductsCrud() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Categorías",
              tabIcon: ListAlt,
              tabContent: <GetProducts />,
            },
            {
              tabName: "SKU",
              tabIcon: ListAlt,
              tabContent: <GetProducts />,
            },
            {
              tabName: "Quiebre",
              tabIcon: ListAlt,
              tabContent: <GetProducts />,
            },
            {
              tabName: "Precios",
              tabIcon: ListAlt,
              tabContent: <GetProducts />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
