import { useEffect, createRef } from 'react';
import * as ROUTES from '../constants/routes';
import { Route } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MyCarousel from '../Pages/Common/Login/Carousel';
//routes
//import routes from '../config/routes.js';

//assets
import logo from '../assets/img/chek-blanca.png';

//styles
import styles from '../assets/jss/trade-app/layouts/authStyle.js';
import LoginPage from '../Pages/Common/Login/LoginPage';

const useStyles = makeStyles(styles);

export default function AuthLayout(props) {
  const { ...rest } = props;

  // ref for the wrapper div
  const wrapper = createRef();
  // styles
  const classes = useStyles();

  useEffect(() => {
    document.body.style.overflow = 'unset';
    return function cleanup() {};
  });

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === `${ROUTES.L_AUTH}`) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = routes => {
    let activeRoute = 'Check';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.carousel}>
        <img src={logo} className={classes.logo}/>
        <MyCarousel />
      </div>
      <div className={classes.login}>
        <LoginPage />
      </div>
    </div>
  );
}

{
  /* <AuthNavbar {...rest} /> */
}
/* <div className={classes.wrapper} ref={wrapper}>
<div
  className={classes.fullPage}
  style={{ backgroundImage: 'url(' + getBgImage() + ')' }}
>
  <Switch>
    {getRoutes(routes)}
    <Redirect
      from={`${ROUTES.L_AUTH}`}
      to={`${ROUTES.L_AUTH + ROUTES.LANDING}`}
    />
  </Switch>
</div>
</div> */
