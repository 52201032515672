import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
import Store from "@material-ui/icons/Store";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import InfoAreaLanding from "../../../../components/InfoArea/InfoAreaLanding.js";

import styles from "../../../../assets/jss/trade-app/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Hablemos del producto</h2>
          <h5 className={classes.description}>
            Consectetur incididunt est magna ex incididunt aliquip dolore
            aliquip. Excepteur magna velit amet labore magna cupidatat tempor et
            est occaecat amet ipsum incididunt consequat. Nostrud non amet
            pariatur cupidatat veniam consectetur duis aute quis in minim est
            officia.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoAreaLanding
              title="Trade Marketing"
              description="Qui dolore qui incididunt minim dolor anim exercitation pariatur."
              icon={Timeline}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoAreaLanding
              title="Información en tiempo real"
              description="Esse velit magna ipsum qui commodo in laboris ex adipisicing nisi consequat consequat aute."
              icon={Store}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoAreaLanding
              title="Clientes"
              description="Duis veniam dolore magna officia id mollit anim laboris dolor ea ipsum ullamco veniam est."
              icon={Group}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
