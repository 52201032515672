const comercials = [
  { ID: 'SM', name: 'Sandra Mariño', email: 'smarino@dgroupsa.com.ar' },
  { ID: 'MR', name: 'Mariana Romjem', email: 'mromjem@dgroupsa.com.ar' },
  { ID: 'CL', name: 'Christian Lopez', email: 'clopez@dgroupsa.com.ar' },
  { ID: 'LS', name: 'Lorena Spretz', email: 'lorena.spretz@dgroupsa.com.ar' },
  { ID: 'LG', name: 'Lionel Garcia', email: 'lgarcia@dgroupsa.com.ar' },
  { ID: 'LL', name: 'Lorena Lionel', email: 'lorena.spretz@dgroupsa.com.ar' },
  { ID: 'PG', name: 'Paola Gummy', email: 'pgummy@dgroupsa.com.ar' },
  { ID: 'CS', name: 'Constanza Sarafoglu', email: 'csarafoglu@dgroupsa.com.ar' }
];

export default comercials;
