import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    defaultFont
  } from '../assets/jss/trade-app';

const StyledTableCell =  withStyles({
  head: {
    backgroundColor: '#9e9e9e',
    color: 'white',
  },
  body: {
    fontFamily: defaultFont,
    color: 'gray'
  },

})(TableCell);

const useStyles = makeStyles({
    table: {
      //minWidth: 700,
    },
  });

function createData(pra, renov, when) {
  return { pra, renov, when };
}

const rows = [
  createData('Foto Visita', 'Semanal', 'Todos los Lunes'),
  createData('Foto Semanal', 'Semanal', 'Todos los Lunes'),
  createData('Foto Quincenal', 'Quincenal', 'Los Lunes cada 15 días'),
  createData('Foto Mensual', 'Mensual', 'El día 1 de cada mes'),
];

export default function TableOFC() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">PRA</StyledTableCell>
            <StyledTableCell align="center">Se renueva</StyledTableCell>
            <StyledTableCell align="center">Cuándo</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.pra}>
              <StyledTableCell align="center">{row.pra}</StyledTableCell>
              <StyledTableCell align="center">{row.renov}</StyledTableCell>
              <StyledTableCell align="center">{row.when}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}