import { createContext, useContext, useState } from 'react';
import { Carousel } from './carousel';

const CarouselContext = createContext({
  report: {},
  tileInfo: {},
  setCarouselInfo: () => {},
  disableAction: false
});

export const useCarousel = () => useContext(CarouselContext);

export default function CarouselProvider({ children, disableAction = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const [carousel, setCarousel] = useState({ report: {}, tileInfo: {} });

  const close = () => {
    setIsOpen(false);
    setCarousel({ report: {}, tileInfo: {} });
  };

  const setCarouselInfo = ({ report, tileInfo }) => {
    setCarousel(prev => ({
      report: report ?? prev.report,
      tileInfo: tileInfo ?? prev.tile
    }));
    setIsOpen(true);
  };

  return (
    <CarouselContext.Provider
      value={{
        ...carousel,
        setCarouselInfo,
        disableAction
      }}
    >
      {isOpen && <Carousel close={close} />}
      {children}
    </CarouselContext.Provider>
  );
}
