const chains = [
  { ID: 'AIE', name: 'Aiello' },
  { ID: 'ALV', name: 'Alvear' },
  { ID: 'ATO', name: 'Atomo' },
  { ID: 'BAR', name: 'Barale' },
  { ID: 'BAS', name: 'Basualdo' },
  { ID: 'BEC', name: 'Becerra' },
  { ID: 'CAF ', name: 'Cafe America' },
  { ID: 'CAP ', name: 'Capo' },
  { ID: 'CAR ', name: 'Caromar' },
  { ID: 'INC', name: 'Carrefour' },
  { ID: 'CER', name: 'Cereales' },
  { ID: 'CHM', name: 'Chango Mas' },
  { ID: 'COM', name: 'Comodin' },
  { ID: 'COO', name: 'Cooperativa' },
  { ID: 'COR', name: 'Cordiez' },
  { ID: 'COT', name: 'Coto' },
  { ID: 'DAR', name: 'Dar' },
  { ID: 'DDI', name: 'Ddi' },
  { ID: 'DEP', name: 'Delca Pintos' },
  { ID: 'DIA', name: 'Diarco' },
  { ID: 'DIN', name: 'Dinosaurio' },
  { ID: 'DIS', name: 'Disco' },
  { ID: 'DON', name: 'Don Emilio' },
  { ID: 'EAS', name: 'Easy' },
  { ID: 'TNL', name: 'El Tunel' },
  { ID: 'FMC', name: 'Farmacity' },
  { ID: 'FER', name: 'Ferniplast' },
  { ID: 'FRI', name: 'Frigorifico' },
  { ID: 'GUI', name: 'Guirao' },
  { ID: 'HER', name: 'Hergo' },
  { ID: 'ISI', name: 'Isis' },
  { ID: 'JOS', name: 'Josimar' },
  { ID: 'JBO', name: 'Jumbo' },
  { ID: 'KIL', name: 'Kibel' },
  { ID: 'LAG', name: 'La  Gallega' },
  { ID: 'LAE', name: 'La Economia' },
  { ID: 'LAR', name: 'La Reina' },
  { ID: 'LIB', name: 'Libertad' },
  { ID: 'MBS', name: 'Mayorista Buen Sol' },
  { ID: 'MKO', name: 'Makro' },
  { ID: 'MAL', name: 'Malambo' },
  { ID: 'MAM', name: 'Mami' },
  { ID: 'MAX', name: 'Mariano Max' },
  { ID: 'MXC', name: 'Maxiconsumo' },
  { ID: 'MED', name: 'Medamax' },
  { ID: 'MIC', name: 'Micropack' },
  { ID: 'MOD', name: 'Modelo' },
  { ID: 'NIN', name: 'Nini' },
  { ID: 'NIP', name: 'Nipon' },
  { ID: 'OSC', name: 'Oscar David' },
  { ID: 'PET', name: 'Petrelli' },
  { ID: 'PIA', name: 'Piantoni' },
  { ID: 'SAN', name: 'San Ramon' },
  { ID: 'SOD', name: 'Sodimac' },
  { ID: 'SUP', name: 'Super Imperio' },
  { ID: 'SUT', name: 'Super Top' },
  { ID: 'SUM', name: 'Supremo' },
  { ID: 'TAD', name: 'Tadicor' },
  { ID: 'TOL', name: 'Toledo' },
  { ID: 'TOR', name: 'Tornado' },
  { ID: 'UPA', name: 'Upa' },
  { ID: 'VEA', name: 'Vea' },
  { ID: 'VIT', name: 'Vital' },
  { ID: 'WMT', name: 'Walmart' },
  { ID: 'YGR', name: 'Yaguar' },
];



export default chains