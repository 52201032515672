const clients = [
  {
    ID: '9',
    companyName:'Establecimiento Sas Ignacio S.A.',
    name: 'San Ignacio',
    adminId: 'FV',
    comercialId: 'LL',
    address: 'Callao 384 5 2 - Capital Federal - Cp 1022',
    CUIT: '30-55945309-5',
    periodReportId: 'FS',
    control: 'true',
    contactName: 'Christopher Colasante'
  }
];

export default clients;
