import { useState, useEffect } from 'react';
import CustomLinearProgress from '../../../components/CustomLinearProgress/CustomLinearProgress';
import Report from '../../Admin/Revision/Report';
import { useClientsProvider } from '../../../api/filters/ClientsProvider';
import CarouselProvider from '../../Admin/Revision/carousel/CarouselProvider';

export default function FilterImages({ type }) {
  const [loading, setLoading] = useState();
  const [filteredReports, setFilteredReports] = useState([]);
  const { reports, category } = useClientsProvider();

  useEffect(() => {
    if (!reports) return;

    setLoading(true);
    const newReports = reports.reduce((reports, report) => {
      if (!report.revised) return reports;

      const categories = report.categories.reduce((categories, category) => {
        if (!category.images) return categories;

        const baseImages = category.images.filter(image => !image.isDeleted);

        const images = baseImages.filter(image => {
          if (type === 'favorites' && image.favorite) return true;
          else if (type === 'pendings' && !report.revised) return true;
          else if (type === 'revised' && report.revised) return true;
          else if (type === 'all') return true;
          else return false;
        });

        if (images.length === 0) return categories;

        return [
          ...categories,
          {
            ...category,
            images
          }
        ];
      }, []);

      if (categories.length === 0) return reports;

      return [
        ...reports,
        {
          ...report,
          categories,
          realCategories: report.categories
        }
      ];
    }, []);

    setFilteredReports(newReports);
    setLoading(false);
  }, [reports, type]);

  if (loading) return <CustomLinearProgress />;

  return (
    <CarouselProvider disableAction>
      {filteredReports.map(report => (
        <Report
          key={report.id}
          report={report}
          category={category}
          disableAction
          revisable={false}
        />
      ))}
    </CarouselProvider>
  );
}
