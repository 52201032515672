import ButtonBase from './ButtonBase';
import { cardTitle } from '../../../assets/jss/trade-app.js';
import { useFilters } from '../../../api/filters/FiltersProvider';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import CustomLinearProgress from '../../../components/CustomLinearProgress/CustomLinearProgress';

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  icon: {
    color: '#333333',
    margin: '10px auto 0',
    width: '130px',
    height: '130px',
    border: '1px solid #E5E5E5',
    borderRadius: '50%',
    lineHeight: '174px',
    '& svg': {
      width: '55px',
      height: '55px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      width: '55px',
      fontSize: '55px'
    }
  },
  marginTop30: {
    marginTop: '30px'
  }
};

const useStyles = makeStyles(styles);

export default function SelectPhotos({ showFilters, type }) {
  const {
    clientsData,
    loading,
    client,
    setClient,
    setBranch,
    setChain,
    setCategory
  } = useFilters();

  return (
    <>
      {loading && <CustomLinearProgress />}
      {clientsData.length > 0 && (
        <GridContainer>
          <CustomTabs
            headerColor="primary"
            plainTabs
            tabs={clientsData.map(c => ({
              onClick: () => {
                if (c.ID === client) return;
                setCategory({});
                setBranch({});
                setChain({});
                setClient(c.ID);
              },
              tabName: c.name,
              tabContent: <ButtonBase showFilters={showFilters} type={type} />
            }))}
          />
        </GridContainer>
      )}
    </>
  );
}
