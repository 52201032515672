import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "transparent",
    padding: "30px",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function Loader() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RotateSpinner />
    </div>
  );
}

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% { 
        transform: rotate(1340deg); 
        opacity: 0.05;
    }
`;

const getBalls = ({
  countBalls,
  radius,
  angle,
  color,
  size,
  ballSize,
  sizeUnit,
}) => {
  const balls = [];
  const offset = ballSize / 2;
  for (let i = 0; i < countBalls; i++) {
    const y = Math.sin(angle * i * (Math.PI / 180)) * radius - offset;
    const x = Math.cos(angle * i * (Math.PI / 180)) * radius - offset;
    balls.push(
      <Ball
        color={color}
        ballSize={ballSize}
        size={size}
        x={y}
        y={x}
        key={i.toString()}
        index={i}
        sizeUnit={sizeUnit}
      />
    );
  }
  return balls;
};

const RotateSpinner = ({ size, color, loading, sizeUnit }) => {
  const radius = size / 2;
  const countBalls = 6;
  const ballSize = size / 5;
  const angle = 360 / countBalls;
  return (
    loading && (
      <Wrapper size={size}>
        {getBalls({
          countBalls,
          radius,
          angle,
          color,
          size,
          ballSize,
          sizeUnit,
        })}
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => `${props.size}${props.sizeUnit}`};
  height: ${(props) => `${props.size}${props.sizeUnit}`};
`;

const Ball = styled.div`
  position: absolute;
  left: 50%;
  top: 0%;
  width: ${(props) => `${props.ballSize}${props.sizeUnit}`};
  height: ${(props) => `${props.ballSize}${props.sizeUnit}`};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  transform: translateX(-50%) translateY(100%);
  transform-origin: 0 250% 0;
  animation: ${rotate} 4s both infinite;
  animation-timing-function: cubic-bezier(
    0.5,
    ${(props) => props.index * 0.3},
    0.9,
    0.9
  );
`;

RotateSpinner.defaultProps = {
  loading: true,
  size: 45,
  color: "#00796b",
  sizeUnit: "px",
};

RotateSpinner.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  sizeUnit: PropTypes.string,
};

//export default RotateSpinner;
