// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";

import GetUsers from "./Crud/GetUsers";
import CreateUser from "./Crud/CreateUser";

import { AddCircle, ListAlt } from "@material-ui/icons";


export default function UsersCrud() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Usuarios",
              tabIcon: ListAlt,
              tabContent: <GetUsers />,
            },
            {
              tabName: "Nuevo Usuario",
              tabIcon: AddCircle,
              tabContent: <CreateUser />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
