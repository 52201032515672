
//LAYOUTS//
export const L_AUTH = '/auth'
export const L_ADMIN = '/admin'
export const L_CLIENT = '/client'
export const L_SUPERADMIN = '/sadmin'

///////////////////////
////** Commons **//////
///////////////////////
export const LANDING = '/home';
export const LOGIN = '/login';
export const CONTACT = '/contacto';
export const ERROR = '/error';
export const UNAUTHORIZED = '/no-autorizado';


/////////////////////
////** Admin **//////
/////////////////////
//Dashboard
export const ADASHBOARD = '/dash-a';
//CRUD clients
export const CLIENTS = '/clientes';
//CRUD coverages
export const COVERAGES = '/anexos';
//CRUD categories
export const PRODUCTS = '/productos';
//CRUD pdv
export const RETAIL = '/tiendas';
//Pictures administration
export const REVISION = '/revision';
//Productivity analytics
export const PRODUCTIVITY = '/productividad';


////////////////////
////** Client **////
////////////////////

//Dashboard
export const CDASHBOARD = '/dash-c';
export const REPORTS = '/reportes'

/////////////////////////
////** Super Admin **////
/////////////////////////
//Dashboard
export const SDASHBOARD = '/dash-s';

//Crud user 
export const USERS = '/usuarios';

//Crud client 
export const ALLCLIENTS = '/clientes';

