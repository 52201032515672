import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles({
  select: {
    width: '300px'
  }
});

export default function DeleteTile({ open, onClose, onDelete }) {
  const [reason, setReason] = useState();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClick={e => e.stopPropagation()}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Eliminar Imagen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecciona un motivo por el cual deseas eliminar esta imagen
        </DialogContentText>

        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Motivo</InputLabel>
          <Select
            className={classes.select}
            autoWidth
            onChange={e => {
              e.stopPropagation();
              setReason(e.target.value);
            }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            defaultValue="Elije una opcion"
            value={reason}
            label="Motivo"
          >
            <MenuItem value="Error de ejecución">Error de ejecución</MenuItem>
            <MenuItem value="Mala calidad de fotografía">
              Mala calidad de fotografía
            </MenuItem>
            <MenuItem value="No corresponde al cliente/cadena">
              No corresponde al cliente/cadena
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="gray" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={e => {
            e.stopPropagation();
            onDelete(reason);
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
