import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function Error({ msg }) {
  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {msg}
    </Alert>
  );
}
