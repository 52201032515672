import React, { useContext } from 'react';
import { FirebaseContext } from '../Firebase';
import { useHistory } from 'react-router-dom';
import { getItem, removeItem } from '../Services/localStorage.js';
import MenuItem from '@material-ui/core/MenuItem';

const SignOutButton = ({ className, onClick }) => {
  const { firebase } = useContext(FirebaseContext);

  const history = useHistory();

  const logout = () => {
    onClick();

    firebase.auth
      .signOut()
      .then(() => {
        removeItem('key');
        removeItem('displayName');
        removeItem('photoUrl');
        history.push('/auth/login');
      })
      .catch(error => {
        console.log('Ocurrió un error al momento de cerrar sesión.', error);
      });
  };
  return (
    <MenuItem className={className} onClick={logout}>
      Cerrar sesión
    </MenuItem>
  );
};

export default SignOutButton;
