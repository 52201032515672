// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import NotInterested from "@material-ui/icons/NotInterested";

import styles from "../../assets/jss/trade-app/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

export default function UnauthorizedErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>
            <NotInterested style={{ width: "150px", height: "150px" }} />
          </h1>
          {/* <h2 className={classes.subTitle}>Error</h2> */}
          <h4 className={classes.subTitle}>
            No autorizado para realizar esta operación
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
