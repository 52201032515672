import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCarousel } from './CarouselProvider';
import { CarouselElement } from './carouselElement';
import EditMode from './editMode';

const Wrapper = styled.div`
  z-index: 1201;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-tap-highlight-color: transparent;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: white;
`;

const CarouselDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  max-width: 100vw;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  position: relative;
`;

const CloseButton = styled.a`
  position: absolute;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  text-decoration: none;
  opacity: 0.6;
  font-size: 20px;
  top: 10px;
  right: 10px;

  :hover {
    opacity: 1;
    color: white;
  }
`;

const SliderButton = styled.a`
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 10;
  left: ${props => (props.left ? `50px` : `unset`)};
  right: ${props => (props.right ? `50px` : `unset`)};
  bottom: 50px;
  cursor: pointer;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: opacity 0.5s ease;
  color: white !important;
  font-size: 50px;
  &:hover {
    opacity: 1;
  }
`;

/**
 *
 * @param {object} props
 * @param {Function} props.close
 */
export function Carousel({ close }) {
  const carouselRef = useRef();
  const [isEdit, setEdit] = useState(false);
  let mainPanel = document.querySelector('#mainpanel');
  let lastScroll = 0;

  const {
    report,
    tileInfo: { tile, catIndex }
  } = useCarousel();

  let category = report.categories[catIndex];

  const hotKeys = e => {
    switch (e.key) {
      case 'Escape':
        close();
        break;
      case 'ArrowLeft':
        if (!isEdit) lastSlide();
        break;
      case 'ArrowRight':
        if (!isEdit) nextSlide();
        break;
      default:
        break;
    }
  };

  const scrollBehaviour = e => {
    e.preventDefault();
    e.stopPropagation();
    e.target.scrollTop = lastScroll;
  };

  useEffect(() => {
    //Handle events
    if (tile) {
      lastScroll = mainPanel.scrollTop;
      mainPanel.scrollTop = lastScroll;
      mainPanel.addEventListener('scroll', scrollBehaviour);
      document.addEventListener('keyup', hotKeys);
    }

    //Sleep ZZzzzz
    return () => {
      document.removeEventListener('keyup', hotKeys);
      mainPanel.removeEventListener('scroll', scrollBehaviour);
    };
  }, []);

  useEffect(() => {
    //Handle slide
    if (tile && !isEdit) {
      let index = category.images.indexOf(tile);
      let refElem = carouselRef.current.children[index < 0 ? 0 : index];
      carouselRef.current.insertBefore(refElem, carouselRef.current.firstChild);
    }
  }, [report, isEdit]);

  const toggleEditMode = () => {
    setEdit(prev => !prev);
  };

  const nextSlide = () => {
    if (carouselRef.current?.children.length > 1) {
      const firtsElement = carouselRef.current.firstElementChild;

      carouselRef.current.style.transition = `500ms ease-out all`;

      carouselRef.current.style.transform = `translateX(-${firtsElement.offsetWidth}px)`;

      const nextTransition = () => {
        carouselRef.current.style.transition = 'none';
        carouselRef.current.style.transform = `translateX(0)`;
        carouselRef.current.appendChild(firtsElement);
        carouselRef.current.removeEventListener(
          'transitionend',
          nextTransition
        );
      };

      carouselRef.current.addEventListener('transitionend', nextTransition);
    }
  };

  const lastSlide = () => {
    if (carouselRef.current && carouselRef.current.children.length > 1) {
      const lastChild = carouselRef.current.lastElementChild;

      carouselRef.current.insertBefore(
        lastChild,
        carouselRef.current.firstChild
      );

      carouselRef.current.style.transition = 'none';
      carouselRef.current.style.transform = `translateX(-${lastChild.offsetWidth}px)`;

      setTimeout(() => {
        carouselRef.current.style.transition = `500ms ease-out all`;
        carouselRef.current.style.transform = `translateX(0)`;
      }, 30);
    }
  };

  return (
    <Wrapper>
      <CloseButton onClick={close.bind(this)}>
        <i className="fas fa-times"></i>
      </CloseButton>
      {isEdit ? (
        <EditMode close={toggleEditMode} />
      ) : (
        <>
          <CarouselDiv ref={carouselRef}>
            {category.images.map((_tile, index) => (
              <CarouselElement
                tile={_tile}
                toggleEdit={toggleEditMode}
                key={index}
                index={index}
              />
            ))}
          </CarouselDiv>
          {category.images.length > 1 && (
            <>
              <SliderButton onClick={lastSlide.bind(this)} left>
                <i className="fas fa-angle-left"></i>
              </SliderButton>
              <SliderButton onClick={nextSlide.bind(this)} right>
                <i className="fas fa-angle-right"></i>
              </SliderButton>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}
