import { useState, useEffect } from 'react';
import { useAdmin } from '../../../api/useAdmin';
import { useFilters } from '../../../api/filters/FiltersProvider';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import FilterBar from './FilterBar';
import ChainsCardDetail from './cards/ChainsCardDetail';
import { DashboardCards } from './cards/DashboardCards';
import { PhotosCardDetail } from './cards/PhotosCardDetail';
import { VisitsCardDetail } from './cards/VisitsCardDetail';
import { ComplianceCardDetail } from './cards/ComplianceCardDetail';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
//styles
import styles from '../../../assets/jss/trade-app/views/dashboardStyle.js';

const useStyles = makeStyles(styles);

export default function DashboardAdmin() {
  const { name } = useAdmin();
  const classes = useStyles();
  const { client, chain } = useFilters();
  // Control Cards Open-Close
  const [chainsCardOpen, setChainsCardOpen] = useState(false);
  const [photosCardOpen, setPhotosCardOpen] = useState(false);
  const [visitsCardOpen, setVisitsCardOpen] = useState(false);
  const [complianceCardOpen, setComplianceCardOpen] = useState(false);

  // Handlers
  const handleComplianceCard = () => {
    setComplianceCardOpen(!complianceCardOpen);
  };
  const handleChainsCard = () => {
    setChainsCardOpen(!chainsCardOpen);
  };
  const handlePhotosCard = () => {
    setPhotosCardOpen(!photosCardOpen);
  };
  const handleVisitsCard = () => {
    setVisitsCardOpen(!visitsCardOpen);
  };

  useEffect(() => {
    setComplianceCardOpen(false);
    setChainsCardOpen(false);
    setPhotosCardOpen(false);
    setVisitsCardOpen(false);
  }, [client, chain]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h3>{`¡Hola ${name}!`}</h3>
          <h5>Filtrá las opciones para conocer el estado de tus cuentas:</h5>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <FilterBar />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <DashboardCards
            classes={classes}
            handleComplianceCard={handleComplianceCard}
            handleChainsCard={handleChainsCard}
            handlePhotosCard={handlePhotosCard}
            handleVisitsCard={handleVisitsCard}
            chainsCardOpen={chainsCardOpen}
            photosCardOpen={photosCardOpen}
            visitsCardOpen={visitsCardOpen}
            complianceCardOpen={complianceCardOpen}
          />
          {chainsCardOpen && <ChainsCardDetail />}
          {photosCardOpen && <PhotosCardDetail />}
          {visitsCardOpen && <VisitsCardDetail classes={classes} />}
          {complianceCardOpen && <ComplianceCardDetail classes={classes} />}
        </GridItem>
      </GridContainer>
    </>
  );
}
