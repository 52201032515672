import { useState, useEffect } from 'react';
import CustomLinearProgress from '../../../components/CustomLinearProgress/CustomLinearProgress';
import Report from './Report';
import { useFilters } from '../../../api/filters/FiltersProvider';
import CarouselProvider from './carousel/CarouselProvider';
import { isType } from '../../../utils/isType';

export default function FilterImages({ type }) {
  const [loading, setLoading] = useState();
  //provisorio para prueba
  const [filteredReports, setFilteredReports] = useState([]);
  const { reports, category } = useFilters();

  useEffect(() => {
    if (!reports) return;

    setLoading(true);
    const newReports = reports.reduce((reports, report) => {
      if (type === 'revised' && !report.revised) return reports;
      if (type === 'pendings' && report.revised) return reports;

      const categories = report.categories.reduce((categories, category) => {
        if (!category.images) return categories;
        const baseImages = category.images.filter(image => !image.isDeleted);

        const images = baseImages.filter(image => isType(type, report, image));

        if (images.length === 0) return categories;

        return [
          ...categories,
          {
            ...category,
            images
          }
        ];
      }, []);

      if (categories.length === 0) return reports;

      return [
        ...reports,
        {
          ...report,
          categories,
          realCategories: report.categories
        }
      ];
    }, []);

    setFilteredReports(newReports);
    setLoading(false);
  }, [reports, type]);

  if (loading) return <CustomLinearProgress />;

  return (
    <CarouselProvider>
      {filteredReports.map(report => (
        <Report key={report.id} report={report} category={category} />
      ))}
    </CarouselProvider>
  );
}
