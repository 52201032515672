import { useState, useEffect } from 'react';
import AccessTime from '@material-ui/icons/AccessTime';
import ChartCate from '../ChartCategory';
import ChartResp from '../ChartResp';
import ChartChains from '../ChartChains';
import Card from '../../../../components/Card/Card';
import Table from './Table';
import CardBody from '../../../../components/Card/CardBody';
import GridItem from '../../../../components/Grid/GridItem';
import CardFooter from '../../../../components/Card/CardFooter';
import GridContainer from '../../../../components/Grid/GridContainer';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import {
  primaryBackgroundText,
  primaryColor
} from '../../../../assets/jss/trade-app';
import firebase from '../../../../Firebase';
import LinearProgress from '@material-ui/core/LinearProgress';

export const ComplianceCardDetail = ({ classes, rows }) => {
  const {
    client,
    chain,
    branchesXChain,
    branchesXClient,
    reportsXClient,
    reportsXChain,
    zones,
    categories,
    allBranches,
    supervisors,
    OFC,
    OFCpendings
  } = useFilters();
  const [dataSupervisors, setDataSupervisors] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataChainsChart, setDataChainsChart] = useState([]);
  const [labelsSupervisors, setLabelsSupervisors] = useState([]);
  const [labelsCategories, setLabelsCategories] = useState([]);
  const [labelsChainsChart, setLabelsChainsChart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPendings] = useState(0);
  //const [OFCpendings, setOFCpendings] = useState([]);
  const [dataChain, setDataChain] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const [OFCPorcIncump, setOFCPorcIncump] = useState();

  const columns = [
    {
      title: 'Zona',
      field: 'zone'
    },
    {
      title: 'Cadena',
      field: 'chain'
    },
    {
      title: 'Punto de venta',
      field: 'branch'
    },
    {
      title: 'Último registro',
      field: 'lastReport'
    },
    {
      title: 'Supervisor',
      field: 'supervisor'
    }
    // {
    //   title: 'Categorias con foto',
    //   field: 'complete'
    // }
  ];

  const getPendings = () => {
    let pendings = 0;
    pendings =
      client && chain.ID
        ? branchesXChain.length - reportsXChain.length
        : !chain.ID
        ? branchesXClient.length - reportsXClient.length
        : 0;
    return pendings;
  };

  useEffect(() => {
    const pendings = getPendings();
    setPendings(pendings);
  }, [client, chain]);

  useEffect(() => {
    getOFCPorcIncump()
  }, [OFC]);

  const getOFCPorcIncump = () => {
    setOFCPorcIncump(Math.trunc((OFCpendings.length * 100) / OFC.length))
  }

  const getDataXChain = async () => {
    try {
      const dataXChain = await Promise.all(
        branchesXChain.map(async comp => {
          let nComp = {
            chain: `${comp.chainName}`,
            branch: `${branchesXChain.filter(b => b.ID == comp.ID)[0]?.name}`,
            supervisor: `${
              supervisors.filter(s => s.ID == zones.filter(z => z.ID == comp.zoneId)[0]?.supervisorId)[0]?.name
            }`,
            zone: `${zones.filter(z => z.ID == comp.zoneId)[0]?.name}`,
            lastReport: `${await firebase.db
              .collection('reports')
              .where('branchId', '==', `${comp.ID}`)
              .orderBy('createdAt', 'asc')
              .get()
              //.limit(1)
              .then(resp => {
                if (resp.docs.length == 0) {
                  return 'Sin registros';
                } else {
                  let date;
                  resp.docs.map(obj => {
                    date = obj.data().createdAt.toDate().toLocaleString();
                  });
                  return date;
                }
              })
              .catch(error => {
                console.log('Error: ' + error);
              })}`
            //lastReport: await getReportDate(comp.ID),
          };
          return nComp;
        })
      );
      return dataXChain.filter(
        c => c.lastReport == 'Sin registros' || !c.complete
      );
    } catch (error) {
      console.error(`getDataXChain. Ocurrió el error: ${error}`);
    }
  };

  const getDataXClient = async () => {
    try {
      const dataXClient = await Promise.all(
        branchesXClient.map(async comp => {
          let nCompCli = {
            chain: `${comp.chainName}`,
            branch: `${branchesXClient.filter(b => b.ID == comp.ID)[0]?.name}`,
            supervisor: `${
              supervisors.filter(s => s.ID == zones.filter(z => z.ID == comp.zoneId)[0]?.supervisorId)[0]?.name
            }`,
            zone: `${zones.filter(z => z.ID == comp.zoneId)[0]?.name}`,
            lastReport: `${await firebase.db
              .collection('reports')
              .where('branchId', '==', `${comp.ID}`)
              .orderBy('createdAt', 'asc')
              .get()
              //.limit(1)
              .then(resp => {
                if (resp.docs.length == 0) {
                  return 'Sin registros';
                } else {
                  let date;
                  resp.docs.map(obj => {
                    date = obj.data().createdAt.toDate().toLocaleString();
                  });
                  return date;
                }
              })
              .catch(error => {
                console.log('Error: ' + error);
              })}`
            //lastReport: await getReportDate(comp.ID),
          };
          return nCompCli;
        })
      );
      return dataXClient.filter(
        c => c.lastReport == 'Sin registros' || !c.complete
      );
    } catch (error) {
      console.error(`getDataXClient. Ocurrió el error: ${error}`);
    }
  };

  const getReportDate = async compId => {
    let date = null;
    try {
      const response = await firebase.db
        .collection('reports')
        .where('branchId', '==', `${compId}`)
        .orderBy('createdAt', 'desc')
        //.limit(1)
        .get();
      date =
        response.docs.length === 0
          ? 'Sin registros'
          : response.docs[0].data().createdAt.toDate().toLocaleString();
    } catch {
      date = 'error';
    }
    return date;
  };

  const getDataSupervisors = () => {
    let zoneIds = [];
    let sups = [];
    //console.log(allBranches)

    let branchIds = OFCpendings.map(pend => pend.branchId);

    //console.log(branchIds)

    branchIds.forEach(br => {
      allBranches.map(branch => {
        if (br == branch.ID) {
          zoneIds.push(branch.zoneId);
        }
      });
    });

    //console.log(zoneIds)

    zoneIds.forEach(z => {
      const supId = zones.filter(zo => zo.ID == z)[0]?.supervisorId;
      sups.push(supId);
    });

    const counts = getCount(sups);
    setLabelsSupervisors(Object.keys(counts));
    setDataSupervisors(Object.values(counts));
  };

  const getDataCategories = () => {
    const param = OFCpendings.map(pending => pending.categoryId);
    const counts = getCount(param);
    const cats = [];
    categories.forEach(c => {
      if (Object.keys(counts).includes(c.ID)) {
        cats.push(c.name);
      }
    });
    setLabelsCategories(cats);
    setDataCategories(Object.values(counts));
  };

  const getDataChainsChart = () => {
    const param = OFCpendings.map(pending => pending.branchId.substr(0, 3));
    const counts = getCount(param);
    setLabelsChainsChart(Object.keys(counts));
    setDataChainsChart(Object.values(counts));
  };

  const getCompliances = async () => {
    setLoading(true);
    let compliances = [];
    if (chain.ID) {
      compliances = await getDataXChain();
      setDataChain(compliances);
    } else {
      compliances = await getDataXClient();
      setDataClient(compliances);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompliances();
  }, []);

  useEffect(() => {
    getCompliances();
  }, [client, chain, branchesXChain, branchesXClient]);

  useEffect(() => {
    getDataSupervisors();
    getDataCategories();
    getDataChainsChart();
  }, [dataClient, dataChain]);

  const getCount = list => {
    const array = list;
    const counts = {};
    array.forEach(obj => {
      counts[obj] = counts[obj] ? counts[obj] + 1 : 1;
    });
    console.log('counts', counts);
    return counts;
  };

  return (
    <>
      {loading && <LinearProgress className={classes.progress} />}
      {client && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <br />
            <h3>Incumplimiento de OFC</h3>
          </GridItem>
          {pending <= 0 ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <p>
                  ¡Felicitaciones! No hay tiendas que no cumplan el OFC para el
                  cliente/sucursal seleccionada.
                </p>
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <br />
                  <p>
                    A continuación podrás visualizar el detalle del {OFCPorcIncump}% del{' '}
                    <span
                      style={{ color: primaryColor[0], fontWeight: 'bold' }}
                    >
                      OFC que aún se encuentra pendiente
                    </span>
                  </p>
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start'
                    }}
                  >
                    {/* <p>
                      Tiendas totales Sin registros: {'  '}
                      <span style={primaryBackgroundText}>{pending}</span>
                    </p> */}
                    <p>
                      Fotografías pendientes: {'  '}
                      <span style={primaryBackgroundText}>
                        {OFCpendings.length}
                      </span>
                    </p>
                    <br />
                    <p>
                      <em>
                        {' '}
                        * Tené en cuenta que esta información guarda relación
                        con la frecuencia de reporte fotográfico acordado con el
                        cliente seleccionado (Ejemplo: si el acuerdo es semanal,
                        el OFC volverá a 0% cada día Lunes)
                      </em>
                    </p>
                  </div>
                </GridItem>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <div data-for="chart">
                        <Card chart>
                          <CardBody>
                            <ChartResp
                              labels={labelsSupervisors}
                              dataResp={dataSupervisors}
                              title={'Incumplimiento por responsables'}
                            />
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> Semana actual
                            </div>
                          </CardFooter>
                        </Card>
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={4}>
                      <div data-for="chart">
                        <Card chart>
                          <CardBody>
                            <ChartChains
                              dataChainsChart={dataChainsChart}
                              labels={labelsChainsChart}
                              title={'Incumplimiento por cadenas'}
                            />
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> Semana actual
                            </div>
                          </CardFooter>
                        </Card>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <div data-for="chart">
                        <Card chart>
                          <CardBody>
                            <ChartCate
                              labels={labelsCategories}
                              dataCate={dataCategories}
                              title={'Incumplimiento por categorías'}
                            />
                          </CardBody>
                          <CardFooter chart>
                            <div className={classes.stats}>
                              <AccessTime /> Semana actual
                            </div>
                          </CardFooter>
                        </Card>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                  {chain.ID ? (
                    <Table
                      title="Tiendas Sin registross"
                      data={dataChain}
                      columns={columns}
                    />
                  ) : (
                    <Table
                      title="Tiendas Sin registross"
                      data={dataClient}
                      columns={columns}
                    />
                  )}
                </GridItem>
              </GridItem>
            </GridContainer>
          )}
        </GridContainer>
      )}
    </>
  );
};
