import { useState, useEffect } from 'react';
import { useFirebase } from '../Firebase';

export const useSuperAdmin = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [superAdminId, setSuperAdminId] = useState();
  const { firebase } = useFirebase();

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(async user => {
      if (user) {
        const user = await firebase.db
          .collection('users')
          .doc(firebase.auth.currentUser.uid)
          .get();

        const { displayName, email } = user.data();
        setName(displayName);
        setEmail(email);

        const superAdmins = await firebase.db
          .collection('superadmins')
          .where('email', '==', `${email}`)
          .get();

        const id = superAdmins.docs.map(doc => doc.data())[0].ID;
        setSuperAdminId(id);
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    name,
    email,
    superAdminId
  };
};

