import { useState, useContext } from 'react';
import firebaseApp from 'firebase/app';
import { withRouter } from 'react-router';
import ForgotPassword from './ForgotPassword';
import Contact from './Contact';

import illustration from '../../../assets/img/LANDINGILUSTRACION.png';
import { setItem, removeItem } from '../../../Services/localStorage.js';

// @material-ui/core components
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//core components
import Error from '../../../myComponents/Error';
import GridItem from '../../../components/Grid/GridItem.js';
import Button from '../../../components/CustomButtons/Button.js';
import GridContainer from '../../../components/Grid/GridContainer.js';

import { FirebaseContext } from '../../../Firebase';
import { makeStyles } from '@material-ui/core/styles';
import {
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor
} from '../../../assets/jss/trade-app.js';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center ',
    padding: '20px, 0'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '5px',
    marginLeft: '15px',
    marginRight: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: '10px',
    position: 'relative'
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  },
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
    color: '#F26B3D',
    fontWeight: '500'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative'
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  customFormControlClasses: {
    margin: '0 12px'
  },
  checkboxLabelControl: {
    margin: '0'
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)'
  },
  illustration: {
    width: '50%',
    padding: '20px'
  }
}));

const LoginPage = ({ history }) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  const { firebase } = useContext(FirebaseContext);

  const handleClose = () => {
    setForgotOpen(false);
  };

  const handleCloseContact = () => {
    setContactOpen(false);
  };

  const setPersistence = async () => {
    const persistence = rememberMe
      ? firebaseApp.auth.Auth.Persistence.LOCAL
      : firebaseApp.auth.Auth.Persistence.SESSION;

    return firebase.auth.setPersistence(persistence);
  };

  const clearErrors = () => {
    setError('');
  };

  // Submit function (Login user)
  async function handleSubmit(e) {
    e.preventDefault();
    const { email, password } = e.target.elements;
    clearErrors();
    setLoading(true);
    await setPersistence();
    removeItem('key');
    removeItem('displayName');
    removeItem('photoUrl');
    await firebase.auth
      .signInWithEmailAndPassword(email.value, password.value)
      .then(userCredential => userCredential.user)
      .then(user => {
        const docRef = firebase.db.collection('users').doc(user.uid);
        return docRef.get();
      })
      .then(doc => {
        if (doc.exists) {
          const { displayName, role, key } = doc.data();
          setItem('key', key);
          setItem('displayName', displayName);
          setItem('photoUrl', firebase.auth.currentUser.photoURL);
          return role;
        }
      })
      .then(role => {
        history.push(`/${role}`);
      })
      .catch(error => {
        if (error.code == 'auth/invalid-email') {
          setError('Formato de correo electrónico inválido');
        } else if (
          error.code == 'auth/wrong-password' ||
          error.code == 'auth/user-not-found'
        ) {
          setError('Usuario y/o contraseña inválido');
        } else if (error.code == 'auth/too-many-request') {
          setError(
            'Demasiados intentos. Intente más tarde o solicite el restablecimiento de su contraseña.'
          );
        } else if (error) {
          setError(error.message);
        }
      });
    setLoading(false);
  }

  return (
    <div className={classes.paper}>
      <img src={illustration} className={classes.illustration} />
      <h3 className={classes.cardTitle}>¡Bienvenido!</h3>
      {error && (
        <GridItem>
          <br />
          <Error msg={error} />
        </GridItem>
      )}
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => setRememberMe(!rememberMe)}
              value={rememberMe}
              color="primary"
              variant="body2"
            />
          }
          label="Recordar usuario"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Iniciar sesión
        </Button>

        <GridContainer justify="center">
          <GridItem mt={1}>
            <Link
              href="#"
              underline="none"
              variant="body2"
              color="textSecondary"
              align="center"
              onClick={() => setForgotOpen(true)}
            >
              <small>¿Olvidó su contraseña?</small>
            </Link>
          </GridItem>
          <br />
          <GridItem mt={1}>
            <Link
              href="#"
              underline="none"
              variant="body2"
              color="textSecondary"
              align="center"
              onClick={() => setContactOpen(true)}
            >
              <small>Si aún no tiene cuenta, contáctenos</small>
            </Link>
          </GridItem>
        </GridContainer>
      </form>
      <ForgotPassword isOpen={forgotOpen} handleClose={handleClose} />
      <Contact isOpen={contactOpen} handleClose={handleCloseContact} />
    </div>
  );
};

export default withRouter(LoginPage);
