import { useState, useEffect } from 'react';

// core components
import SelectPhotos from './SelectPhotos';
import FilterBar from './FilterBar';
import Accordion from './Accordion';

export default function RevisionPage() {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [type, setType] = useState('');

  const showFilters = type => {
    setFiltersVisible(true);
    setType(type);
  };

  return (
    <>
      <SelectPhotos showFilters={showFilters} type={type} />

      {filtersVisible && <FilterBar />}

      <Accordion filtersVisible={filtersVisible} type={type} />
    </>
  );
}
