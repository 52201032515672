import React, { useState } from 'react';
import { useFilters } from '../../../api/filters/FiltersProvider';
//styles
import { makeStyles } from '@material-ui/core/styles';
import drop from '../../../assets/jss/trade-app/customSelectStyle.js';
//core components
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import CustomLinearProgress from '../../../components/CustomLinearProgress/CustomLinearProgress';

// material UI components
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  ...drop,
  root: {
    marginTop: '6px',
    width: '100%'
  },
  heading1: {
    marginLeft: '15px',
    fontSize: '25px',
    color: 'gray',
    fontWeight: 'lighter'
  },
  heading2: {
    marginLeft: '15px',
    fontSize: '20px',
    color: 'gray',
    fontWeight: 'lighter',
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: '15px',
    color: 'gray'
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center',
    padding: '20px',
    '&:hover': {
      backgroundColor: 'primary'
    }
  },
  // column: {
  //   flexBasis: '25%',
  // },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function FilterBar() {
  const classes = useStyles();
  const {
    clientsData,
    loading,
    setBranch,
    setClient,
    setChain,
    client,
    branch,
    chain,
    branches,
    chains,
    category
  } = useFilters();

  const [selectPeriod, setSelectPeriod] = useState('Semana');
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleSelectedFilters = () => {
    const selected = [];
    chain != '' ? selected.push(chain) : 0;
    branch != '' ? selected.push(branch) : null;
    category != '' ? selected.push(category) : null;
    selectPeriod != '' ? selected.push(selectPeriod) : null;

    setSelectedFilters(selected);
  };

  const handleSelectChain = event => {
    setBranch({});
    const c = chains.find(chain => chain.ID === event.target.value);
    setChain(c ?? { name: 'Todas' });
    handleSelectedFilters();
  };

  const handleSelectClient = event => {
    setChain(chains[0]);
    setBranch({});
    setClient(event.target.value);
    handleSelectedFilters();
  };

  if (loading) return <CustomLinearProgress />;

  return (
    <div className={classes.root}>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="select-client"
                  className={classes.selectLabel}
                >
                  Cliente
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={client}
                  onChange={handleSelectClient}
                  inputProps={{
                    name: 'selectClient',
                    id: 'select-client'
                  }}
                >
                  {clientsData.map(client => (
                    <MenuItem
                      key={client.ID}
                      //displayempty
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={client.ID}
                    >
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  shrink
                  htmlFor="select-chain"
                  className={classes.selectLabel}
                >
                  Cadena
                </InputLabel>
                <Select
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={chain.ID ?? ''}
                  onChange={handleSelectChain}
                  inputProps={{
                    name: 'selectChain',
                    id: 'select-chain'
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={''}
                  >
                    Todas
                  </MenuItem>
                  {chains.map(chain => (
                    <MenuItem
                      key={chain.ID}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={chain.ID}
                    >
                      {chain.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            {/*   <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="select-branch"
                  className={classes.selectLabel}
                >
                  Sucursal
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={branch}
                  onChange={handleSelectBranch}
                  inputProps={{
                    name: 'selectBranch',
                    id: 'select-branch'
                  }}
                >
                  {branches.map(branch => (
                    <MenuItem
                      key={branch.ID}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={branch.ID}
                    >
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="select-period"
                  className={classes.selectLabel}
                >
                  Período relevado
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={selectPeriod}
                  onChange={handleSelectPeriod}
                  inputProps={{
                    name: 'selectPeriod',
                    id: 'select-period'
                  }}
                >
                  {period.map((p, i) => {
                    return (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={period[i]}
                      >
                        {p}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem> */}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}