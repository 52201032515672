import { title } from  "../../../trade-app.js";

const productStyle = {
  section: {
    padding: "60px 20px",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999",
    //overflow: "hidden",
    marginTop: "10px",
    fontSize: "14px"
  }
};

export default productStyle;
