// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import Month from "./Month";

export default function MyProductivity() {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Enero",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Febrero",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Marzo",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Abril",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
                       {
              tabName: "Mayo",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Junio",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Julio",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Julio",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Agosto",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Septiembre",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Octubre",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Noviembre",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },
            {
              tabName: "Diciembre",
              //tabIcon: ListAlt,
              tabContent: <Month />,
            },

          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
