import AddReport from './AddReport';
import { useFirebase } from '../../../Firebase';
import GridContainer from '../../../components/Grid/GridContainer';
import { useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button
} from '@material-ui/core';

export default function AddReportPage({ history }) {
  const { firebase } = useFirebase();
  const [openModal, setOpenModal] = useState(true);
  const [openFinish, setOpenFinish] = useState(false);

  const closeNewReport = () => {
    setOpenModal(false);
    history.push('/admin/revision');
  };

  const onFinish = () => {
    setOpenFinish(true);
  };
  const goToReports = () => {
    history.push('/admin/revision');
  };

  const saveReport = async data => {
    //logica de guardado
    const categories = await Promise.all(
      data.categories.map(async category => {
        if (!category.images) return category;

        const images = await Promise.all(
          category.images.map(async image => {
            const task = firebase.storage
              .ref('reports')
              .child(firebase.auth.currentUser.uid)
              .child(image.name);

            await task.putString(image.uri, 'data_url');

            const uri = await task.getDownloadURL();
            return {
              ...image,
              uri
            };
          })
        );
        return {
          ...category,
          images
        };
      })
    );
    const report = await firebase.db.collection('reports').add({
      ...data,
      categories
    });

    console.log((await report.get()).data());
  };

  return (
    <div style={{ justifyContent: 'center' }}>
      <GridContainer>
        <AddReport
          open={openModal}
          setClose={closeNewReport}
          save={saveReport}
          onFinish={onFinish}
        />
      </GridContainer>
      <Dialog open={openFinish} onClose={closeNewReport}>
        <DialogTitle onClose={closeNewReport}>Subiste un reporte</DialogTitle>
        <DialogContent>El reporte fue enviado con éxito</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={goToReports} color="primary">
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
