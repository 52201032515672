import React, { useContext, createContext, useState } from 'react';
import firebase from '../Firebase';

const FirebaseContext = createContext({
  firebase
});

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ firebase }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);

export default FirebaseContext;
