import { useState, useEffect } from 'react';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import {
  primaryBackgroundText,
  primaryColor
} from '../../../../assets/jss/trade-app';
import TableOFC from '../../../../myComponents/TableOFC'

export const PhotosCardDetail = () => {
  const {
    client,
    chain,
    categories,
    branchesXChain,
    frequencyReport,
    OFC,
  } = useFilters();
  const [frequencyString, setFrequencyString] = useState('');
  const [actualOFC, setActualOFC] = useState([]);
  


  const getFrequencyString = () => {
    if (frequencyReport) {
      switch (frequencyReport) {
        case 'FV':
          setFrequencyString('Foto Visita');
          break;
        case 'FS':
          setFrequencyString('Foto Semanal');
          break;
        case 'FQ':
          setFrequencyString('Foto Quincenal');
          break;
        case 'FM':
          setFrequencyString('Foto Mensual');
          break;
        default:
          console.log('Error');
      }
    }
  };

  useEffect(() => {
    getFrequencyString();
    setActualOFC(OFC)
    //console.log(OFC)
  }, [client, chain]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <h3>Objetivo de fotografías contratadas</h3>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <br />
          <div style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            <p>
              Período de reporte fotográfico acordado: {'  '}
              <span style={primaryBackgroundText}>{`${frequencyString}`}</span>
            </p>
          </div>
          <br />

          {chain.ID ? (
            <>
              <h5 style={{ color: primaryColor[0] }}>
                {' '}
                OFC CADENA SELECCIONADA{' '}
              </h5>
              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de tiendas contratadas en la cadena seleccionada:{' '}
                  {'  '}
                  <span
                    style={primaryBackgroundText}
                  >{`${branchesXChain.length}`}</span>
                </p>
              </div>
              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de categorías activas en la cadena seleccionada: {'  '}
                  <span style={primaryBackgroundText}>
                    {' '}
                    {`${categories.length}`}
                  </span>
                </p>
              </div>
            </>
          ) : (
            <>
              <h5 style={{ color: primaryColor[0] }}> OFC TOTAL </h5>

              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de tiendas totales contratadas: {'  '}
                  <span
                    style={primaryBackgroundText}
                  >{`${branchesXChain.length}`}</span>
                </p>
              </div>
              <div
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <p>
                  Número de categorías totales del cliente:{'  '}
                  <span style={primaryBackgroundText}>
                    {' '}
                    {`${categories.length}`}
                  </span>
                </p>
              </div>
            </>
          )}

          <br />
          <p>
            <em>
            {' '}
            * El OFC se calcula en base al PRA (Período de Reporte Acordado) y
            se obtiene del cálculo{' '}
            <span style={{ color: primaryColor[0], fontWeight: 'bold' }}>
              Tiendas contratadas x Categorías
            </span>
            </em>
          </p>         
          <TableOFC/>  
          <br /> 
        </GridItem>
      </GridContainer>
    </>
  );
};
