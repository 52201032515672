import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import GetCoverages from "./GetTables/GetCoverage"
import { ListAlt } from "@material-ui/icons";

export default function CoveragesCrud() {

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            headerColor="primary"
            plainTabs
            tabs={[
              {
                tabName: "Anexos",
                tabIcon: ListAlt,
                tabContent: <GetCoverages />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  }
