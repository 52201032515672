// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.js";
import GetClients from "./GetTables/GetClients";
import CreateClient from "./CreateForms/CreateClient";

import { AddCircle, ListAlt } from "@material-ui/icons";

export default function ClientsCrud() {

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          headerColor="primary"
          plainTabs
          tabs={[
            {
              tabName: "Clientes",
              tabIcon: ListAlt,
              tabContent: <GetClients />,
            },
            {
              tabName: "Nuevo Cliente",
              tabIcon: AddCircle,
              tabContent: <CreateClient />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}
