import { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Graphics, Sprite, Stage, Text } from '@inlet/react-pixi';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { TextStyle } from '@pixi/text';
import styled from 'styled-components';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import { useFirebase } from '../../../../Firebase';
import { PixiViewport } from '../../../../pixijs/Viewbox';
import { useCarousel } from './CarouselProvider';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: '#fff'
  }
}));

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EditActions = styled.div`
  display: flex;
  width: fit-content;
  padding: 8px 4px;
  margin: 20px auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #2c2b2b;
  border-radius: 8px;
`;

const EditAction = styled.a`
  color: white;
  font-size: 14px;
  background: ${props => (props.active ? '#1d1c1c' : 'transparent')};
  margin: auto 5px;
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  user-select: none;
  i {
    margin-right: 5px;
    line-height: 50%;
  }
  :hover {
    background: #494848;
    color: white;
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    `
        background: transparent !important;
        opacity: .3;
    `}
`;

const Canvas = styled(Stage)`
  width: calc(100vw * 9 / 16);
  height: calc(100vh - 17rem);
  background-color: #fff;
  background-image: linear-gradient(#efefef 1px, transparent 1px),
    linear-gradient(to right, #efefef 1px, #fff 1px);
  background-size: 20px 20px;
  border-radius: 18px;
  margin: 20px auto;
`;

const RotationSlider = ({ width, height, rotation, ...props }) => {
  let { x, y } = { ...props };
  const draw = useCallback(
    g => {
      g.clear();
      g.beginFill(0x000000, 0.2);
      g.drawRoundedRect(x, y, width, height, 16);
      g.endFill();

      g.beginFill(0x000000, 0.55);
      g.drawRoundedRect(10, y + 5, 10, height - 15, 16);
      g.endFill();

      g.lineStyle(2, 0x0000000, 0.5);
      g.beginFill(0xffffffff);
      g.drawCircle(15, rotation, 8);
      g.endFill();
    },
    [width, height, rotation]
  );

  return <Graphics interactive={true} {...props} draw={draw} />;
};

function EditMode({ close }) {
  const classes = useStyles();

  const [editMode, setMode] = useState(0);
  const [transform, setTransform] = useState({ width: -1, height: -1 });
  const [isInteractive, setInteractive] = useState(false);
  const [save, setSave] = useState(false);
  const {
    report,
    tileInfo: { tile }
  } = useCarousel();
  const viewportRef = useRef();
  const imageRef = useRef();

  const { firebase } = useFirebase();
  const { setReportsXClient } = useFilters();

  let _width = (document.body.getBoundingClientRect().width * 9) / 16;
  let _height = document.body.getBoundingClientRect().height - 16 * 17;
  const [rotation, setRotation] = useState(0);
  const [rotationDelta, setRotationDelta] = useState(0);

  const handleSlideDown = e => {
    setInteractive(true);
  };

  const handleSlideUp = e => {
    setInteractive(false);
  };

  const handleSlideMove = e => {
    if (isInteractive) {
      let globalY = e.data.global.y;

      setRotation(globalY);
      calculateRotation();
    }
  };

  const handleSaveImage = e => {
    setSave(true);
    let app = viewportRef.current.app;
    setTimeout(() => {
      app.renderer.plugins.extract.canvas(app.stage).toBlob(async b => {
        console.log(tile, report);
        const ref = firebase.storage.ref(
          `reports/${report.createdBy}/${tile.name}`
        );
        ref.put(b).then(async task => {
          let nuri = await task.ref.getDownloadURL();

          const categories = report.realCategories.map(category => {
            if (!category.images) return category;

            const images = category.images.map(image =>
              image.name === tile.name
                ? {
                    ...image,
                    uri: nuri
                  }
                : image
            );

            return {
              ...category,
              images
            };
          });

          await firebase.db.collection('reports').doc(report.id).update({
            categories
          });

          setReportsXClient(reports =>
            reports.map(r => (r.id === report.id ? { ...r, categories } : r))
          );

          setSave(false);
        });
      });
    }, 30);
  };

  const calculateRotation = () => {
    let theta = Math.floor(rotation - rotation / (_height + 20)) / 2;
    setRotationDelta((theta * Math.PI) / 180);
  };

  useEffect(() => {
    console.log(imageRef.current.texture);
    if (imageRef.current) {
      let sprite = imageRef.current;
      let width = sprite.texture.width;
      let height = sprite.texture.height;

      if (transform.width > 1) {
        sprite.width = transform.width;
        sprite.height = transform.height;
        console.log(transform);
        return;
      }

      if (width > height) {
        if (width > _width) {
          width = _width;
          height *= _width / width;
        }
      } else {
        if (height > _height) {
          width *= _height / height;
          height = _height;
        }
      }

      sprite.width = width;
      sprite.height = height;
      setTransform({ width: width, height: height });
      console.log('T', transform);
    }
  }, [imageRef.current]);

  return (
    <Wrapper>
      <Canvas
        mode={editMode}
        width={_width}
        height={_height}
        options={{
          antialias: true,
          autoDensity: true,
          backgroundAlpha: 0
        }}
      >
        <Container
          width={_width}
          height={_height}
          interactive={true}
          pointermove={handleSlideMove.bind(this)}
        ></Container>
        <PixiViewport
          ref={viewportRef}
          screenWidth={_width}
          screenHeight={_height}
          worldWidth={_width}
          worldHeight={_height}
          plugins={['drag', 'pinch', 'wheel', 'decelerate']}
        >
          <Sprite
            scale={{ x: 1, y: 1 }}
            anchor={0.5}
            ref={imageRef}
            image={tile.uri}
            rotation={rotationDelta}
            x={_width / 2}
            y={_height / 2}
          ></Sprite>
        </PixiViewport>
        {!save && editMode == 1 && (
          <>
            <RotationSlider
              rotation={rotation}
              pointerdown={handleSlideDown.bind(this)}
              pointerupoutside={handleSlideUp.bind(this)}
              pointerout={handleSlideUp.bind(this)}
              pointerup={handleSlideUp.bind(this)}
              x={0}
              y={0}
              width={100}
              height={_height}
            ></RotationSlider>
            <Text
              text={Math.floor((rotationDelta * 180) / Math.PI) + ' °'}
              x={30}
              y={rotation - 10}
              style={
                new TextStyle({
                  align: 'center',
                  fontFamily: 'Roboto, Helvetica, "Sans serif"',
                  fontSize: 20,
                  fill: '#ffffff', // gradient
                  stroke: '#191919',
                  strokeThickness: 3,
                  wordWrap: true,
                  wordWrapWidth: 440
                })
              }
            ></Text>
          </>
        )}
      </Canvas>
      <EditActions>
        <EditAction disabled>
          <i className="fas fa-hand-paper"></i> Mover
        </EditAction>
        <EditAction
          onClick={setMode.bind(this, editMode == 1 ? 0 : 1)}
          active={editMode == 1}
        >
          <i className="fas fa-sync-alt"></i> Rotar
        </EditAction>
        <EditAction disabled>
          <i className="fas fa-crop"></i>Recortar
        </EditAction>
        <EditAction onClick={handleSaveImage.bind(this)}>
          <i className="fas fa-save"></i>Guardar
        </EditAction>
        <EditAction onClick={close.bind(this)}>
          <i className="fas fa-angle-left"></i>Volver
        </EditAction>
      </EditActions>
      <Backdrop className={classes.backdrop} open={save}>
        <CircularProgress color="primary"></CircularProgress>
      </Backdrop>
    </Wrapper>
  );
}

export default EditMode;
