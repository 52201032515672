// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Error from "@material-ui/icons/Error";

import styles from "../../assets/jss/trade-app/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>
            <Error style={{ width: "150px", height: "150px" }} />
          </h1>
          <h2 className={classes.subTitle}>Página no encontrada</h2>
          <h4 className={classes.description}>
            Se produjo un error. Inténtelo nuevamente.
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
