import React, { useState, useEffect } from 'react';
import { useClient } from '../api/useClient';
import { ClientsProvider } from '../api/filters/ClientsProvider.js';

import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import ClientNavbar from '../components/Navbars/ClientNavbar.js';
import Footer from '../components/Footer/Footer.js';
//import Sidebar from "../components/Sidebar/Sidebar.js";

import routes from '../config/routes.js';
import logo from '../assets/img/CHEK-NEGRO (1).png';

import styles from '../assets/jss/trade-app/layouts/clientStyle.js';

const useStyles = makeStyles(styles);
let ps;

export default function ClientLayout(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [image, setImage] = useState(require('../assets/img/sidebar.jpg'));
  const [color, setColor] = useState('teal');
  const [bgColor, setBgColor] = useState('black');
  // const [hasImage, setHasImage] = useState(true);

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== '/client/full-screen-maps';
  };
  const getActiveRoute = routes => {
    let activeRoute = 'Chek';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const filterRoutes = routes =>
    routes.filter(x => x.layout === `${ROUTES.L_CLIENT}`);

  const getRoutes = routes => {
    const filteredRoutes = filterRoutes(routes);
    return filteredRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === `${ROUTES.L_CLIENT}`) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <ClientsProvider>
      <div className={classes.wrapper}>
        {/* <Sidebar
        routes={filterRoutes(routes)}
        logoText={"Chek"}
        logo={logo}
        image={image}
        //image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      /> */}
        <div id="mainpanel" className={mainPanelClasses} ref={mainPanel}>
          <ClientNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText="Chek"
            logo={logo}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect
                    from={`${ROUTES.L_CLIENT}`}
                    to={`${ROUTES.L_CLIENT + ROUTES.CDASHBOARD}`}
                  />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(routes)}
                <Redirect
                  from={`${ROUTES.L_CLIENT}`}
                  to={`${ROUTES.L_CLIENT + ROUTES.CDASHBOARD}`}
                />
              </Switch>
            </div>
          )}
          {getRoute() && null}
        </div>
      </div>
    </ClientsProvider>
  );
}
