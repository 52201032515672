// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";

import styles from "../../../../assets/jss/trade-app/views/comingSoonStyle.js";

const useStyles = makeStyles(styles);

export default function GetProducts() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h1 className={classes.title}>
            Próximamente
          </h1>
          <h4 className={classes.subTitle}>
          Podrás administrar los productos de las cuentas a tu cargo
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
