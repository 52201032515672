import { useState } from 'react';
import styled from 'styled-components';
import { useFilters } from '../../../../api/filters/FiltersProvider';
import { useFirebase } from '../../../../Firebase';
import DeleteTile from '../DeleteTile';
import { useCarousel } from './CarouselProvider';

const CarouselContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
`;

const CarouselImage = styled.div`
  background: url(${props => props.img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: calc(110vw * 9 / 16);
  height: calc(110vh - 17rem);
  margin: 20px auto;
  border-radius: 8px;
`;

const CarouselId = styled.p`
  text-align: center;
  margin: 5px auto;
  font-weight: bold;
  font-size: 16px;
`;
const CarouselCategory = styled(CarouselId)`
  font-size: 14px;
`;

const CarouselActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px auto;
`;

const CarouselAction = styled.a`
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto 15px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  opacity: 0.6;
  i {
    margin-right: 5px;
  }

  :hover {
    opacity: 1;
    color: white;
  }
`;

export function CarouselElement({ tile, toggleEdit, index, close }) {
  const [openModal, setOpenModal] = useState();
  const [favorite, setFavorite] = useState(tile.favorite ?? false);
  const { report, disableAction } = useCarousel();
  const { firebase } = useFirebase();
  const { setReportsXClient } = useFilters();

  const handleFavorite = async () => {
    const categories = report.realCategories.map(category => {
      if (!category.images) return category;

      const images = category.images.map(image =>
        image.name === tile.name
          ? {
              ...image,
              favorite: !favorite
            }
          : image
      );

      return {
        ...category,
        images
      };
    });

    await firebase.db.collection('reports').doc(report.id).update({
      categories
    });

    setReportsXClient(reports =>
      reports.map(r => (r.id === report.id ? { ...r, categories } : r))
    );

    setFavorite(!favorite);
  };

  const handleDelete = async reason => {
    await firebase.storage
      .refFromURL(tile.uri)
      .delete()
      .catch(() => console.log('La imagen no existe'));

    const categories = report.realCategories.map(category => {
      if (!category.images) return category;
      const images = category.images.map(image =>
        image.name === tile.name ? { ...image, isDeleted: true, reason } : image
      );

      return {
        ...category,
        images
      };
    }, []);

    await firebase.db.collection('reports').doc(report.id).update({
      categories
    });

    setReportsXClient(reports =>
      reports.map(r => (r.id === report.id ? { ...r, categories } : r))
    );

    //if (closeCarousel) closeCarousel({ data: {}, pictureId: "", actions: [] });

    setOpenModal(false);
  };

  const { branch } = useFilters();

  return (
    <CarouselContainer aria-label={index}>
      <DeleteTile
        onClose={() => setOpenModal(false)}
        open={openModal}
        onDelete={handleDelete}
      />
      <CarouselImage img={tile.uri}></CarouselImage>

      <CarouselId>{tile.comment}</CarouselId>
      <CarouselId>
        {branch.chainName} - {branch.name}
      </CarouselId>
      <CarouselCategory>
        Exhibición {tile.type == 'primary' ? 'Primaria' : 'Secundaria'}
      </CarouselCategory>
      <CarouselActions>
        <CarouselAction href={tile.uri} target="_blank">
          <i className="fas fa-external-link-alt"></i>Abrir en una nueva pestaña
        </CarouselAction>
        {!disableAction && (
          <>
            <CarouselAction onClick={handleFavorite}>
              {favorite ? (
                <>
                  <i className="fas fa-star"></i>Desmarcar como destacada
                </>
              ) : (
                <>
                  <i className="far fa-star"></i>Marcar como destacada
                </>
              )}
            </CarouselAction>
            <CarouselAction onClick={() => setOpenModal(true)}>
              <i className="fas fa-times"></i>Eliminar
            </CarouselAction>
            <CarouselAction onClick={toggleEdit}>
              <i className="fas fa-edit"></i>Editar Imagen
            </CarouselAction>
          </>
        )}
      </CarouselActions>
    </CarouselContainer>
  );
}
