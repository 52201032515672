import MaterialTable from 'material-table';
import moment from 'moment';
import _filefy, { CsvBuilder } from 'filefy';

export default function TableDashboard(props) {
  const { data, columns, title } = props;

  //Export to cvs wiht columns
  const exportedData = data.map(rowData =>
    columns.map(columnDef => rowData[columnDef.field])
  );
  const exportCsv = function () {
    new CsvBuilder(`${title}.csv`)
      .setColumns(columns.map(columnDef => columnDef.title))
      .setDelimeter(';')
      .addRows(exportedData)
      .exportFile();
  };
  return (
    <div>
      <MaterialTable
        title=""
        data={data}
        columns={columns}
        options={{
          exportButton: true,
          exportFileName: `${title}_${moment().format('DD-MM-YYYY')}`,
          exportAllData: true,
          actionsColumnIndex: -1,
          filtering: true,
          headerStyle: {
            backgroundColor: '#9e9e9e',
            color: '#FFF',
            justifyItems: 'center'
          },
          pageSizeOptions: [10, 30, 50],
          pageSize: 10,
          exportCsv
        }}
        localization={{
          header: {
            actions: 'Acciones'
          },

          body: {
            emptyDataSourceMessage: 'No hay registros para mostrar',
            deleteTooltip: 'Eliminar',
            editTooltip: 'Editar',
            addTooltip: 'Añadir registro',
            filterRow: {
              filterPlaceHolder: 'Buscar...',
              filterTooltip: 'Filtrar'
            },
          },
          toolbar: {
            searchTooltip: 'Buscador',
            searchPlaceholder: 'Buscar..',
            exportTitle: 'Exportar',
            exportAriaLabel: 'Exportar',
            toolbarExportName: 'Exportar en CSV'
          },
          pagination: {
            labelRowsSelect: 'registros por página',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página'
          }
        }}
      />
    </div>
  );
}