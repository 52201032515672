import React, { useState, useEffect} from "react";
import firebase from "../../../../Firebase";
import TableCrud from "../../../../components/Table/TableCrud"

const GetChains = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([
    {
      ID: "",
      name: "",
    },
  ]);
  const columns = [
    {
      title: "Identificador",
      field: "ID",
      validate: (rowData) =>
        rowData.ID === ""
          ? { isValid: false, helperText: "Campo obligatorio" }
          : true,
    },
    {
      title: "Nombre",
      field: "name",
      validate: rowData => 
        rowData.name === ""
        ? { isValid: false, helperText: "Campo obligatorio" }
        : true,
    },
  ];


  const getChains = async () => {
    try {
      setLoading(true);
      let data = [];
      let result = await firebase.db.collection("chains").orderBy("name").get();
      data = result.docs.map((aChain) => {
        return {
          ...aChain.data(),
          id: aChain.id,
        };
      });
      setData(data);
    } catch (error) {
      console.error(`getChains. Ocurrió el error: ${error}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    return getChains();
  }, []);

  return (
    <div>
     <TableCrud
        title="Cadenas"
        data={data}
        columns={columns}
        isLoading={loading}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
                firebase.db
                  .collection("chains")
                  .add({
                    ID: newData.ID,
                    name: newData.name,
                  })
                  .then(function () {
                    console.log("Document successfully written!");
                    getChains();
                  })
                  .catch(function (error) {
                    console.error("Error writing document: ", error);
                  });
                resolve();
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
                firebase.db
                  .collection("chains")
                  .doc(`${oldData.id}`)
                  .set({
                    ID: newData.ID,
                    name: newData.name,
                  })
                  .then(function () {
                    console.log("Document successfully written!");
                    getChains();
                  })
                  .catch(function (error) {
                    console.error("Error writing document: ", error);
                  });
                resolve();
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve) => {
                firebase.db
                  .collection("chains")
                  .doc(`${oldData.id}`)
                  .delete()
                  .then(function () {
                    console.log("Document successfully deleted!");
                    getChains();
                  })
                  .catch(function (error) {
                    console.error("Error removing document: ", error);
                  });
                resolve();
            }),
        }}
      />
    </div>
  );
};

export default GetChains;
